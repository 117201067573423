import React from 'react';

export default function ButtonPrimary({
  title,
  className,
  onClick,
  type,
  isDisabled,
}) {
  return (
    <button
      disabled={isDisabled}
      className={`${
        !isDisabled
          ? 'bg-blue1 border border-blue1 hover:border-blue3'
          : 'bg-gray-300 border border-gray-300 hover:border-blue3'
      } px-7 py-3 font-inter font-bold text-sm text-white hover:bg-blue3 ${className}`}
      onClick={onClick}
      type={type}
    >
      {title}
    </button>
  );
}
