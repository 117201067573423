import { doLogin } from 'config/Redux/action';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IconShowPassword, ILLogin } from '../../assets';
import { Button, Error, Input } from '../../components';

export default function Login(props) {
  const { register, handleSubmit, reset, errors } = useForm();
  const [inputError, setInputError] = useState(false);
  const [respon, setRespon] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isLoading);

  const onSubmit = async (data) => {
    try {
      await dispatch(doLogin('/auth/login', data));
      props.history.replace('/dashboard/antrian-klinik');
    } catch (error) {
      setRespon(error);
      reset();
    }
  };

  return (
    <div className="flex items-center max-h-screen ">
      {/* START : Illustration Login */}
      <img
        src={ILLogin}
        alt="Login Illustration"
        className="object-scale-down hidden lg:block lg:h-screen lg:w-1/2 xl:object-cover"
      />
      {/* END : Illustration Login */}

      <div className="flex flex-col justify-center h-screen w-full items-center p-8 lg:w-1/2 md:w-full login-background-img">
        {/* START : Form Login */}
        <div className="bg-blue1 w-full md:w-9/12 p-14 rounded-30 text-white shadow-sm relative z-10">
          <h1 className="font-inter font-bold text-4xl mb-16">Pondok Vaksin</h1>
          <div className="flex justify-between mb-9">
            <h3 className="font-inter font-bold text-2xl ">Login</h3>

            {/* START : Error Messages */}
            {respon === 401 ? (
              <Error message="Email/password yang anda masukkan salah" />
            ) : errors.email ? (
              errors.email.type === 'required' && (
                <Error message={errors.email.message} />
              )
            ) : (
              errors.password && <Error message={errors.password.message} />
            )}
            {/* END : Error Messages */}
          </div>

          <form>
            {/* START : Input Email */}
            <Input
              withValidation
              type="text"
              name="email"
              placeholder="Email"
              onChange={() => setRespon('')}
              className={`${
                errors.email || errors.password || inputError
                  ? 'error-input'
                  : 'focus-input'
              }`}
              autoComplete="off"
              ref={register({
                required: 'Anda belum memasukkan email',
                pattern:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
            />
            {/* END : Input Email */}

            {/* START : Input Password */}
            <Input
              withValidation
              icon={IconShowPassword}
              type="password"
              name="password"
              placeholder="Password"
              onChange={() => setRespon('')}
              className={`${
                errors.email || errors.password || inputError
                  ? 'error-input'
                  : 'focus-input'
              }`}
              ref={register({
                required: 'Anda belum memasukkan password',
              })}
            />
            {/* END : Input Password */}

            <Button
              title="Login"
              className={`${
                isLoading
                  ? 'bg-gray-400 py-4 w-full rounded-10 font-inter font-bold text-white focus:outline-none'
                  : 'btn-login'
              }`}
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </div>
        {/* END : Form Login */}
      </div>
    </div>
  );
}
