import { IconAntrianKosong, IconLoading, IconSearch } from 'assets';
import { Input, LabelStatus } from 'components/atoms';
import API from 'config/Axios';
import React, { useEffect } from 'react';
import { useState } from 'react';

const RunningQueue = () => {
  const [activeQueue, setActiveQueue] = useState([]);
  const [tablesQueue, setTablesQueue] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getRunningQueue = () => {
    return API.get(
      '/staff/queues/running?service_location_id=1&filtered_by_role=1'
    )
      .then((res) => {
        const tempResponse = res.data?.queues
          ?.filter(
            (item, index) =>
              item?.queue_status?.id !== 7 &&
              item?.queue_status?.id !== 8 &&
              item?.queue_number !== null
          )
          .sort((a, b) => a?.queue_number - b?.queue_number);

        const tempActiveQueue = tempResponse?.filter((_, index) => index < 2);
        setActiveQueue(tempActiveQueue);
        const tempTablesQueue = tempResponse?.filter((_, index) => index >= 2);
        setTablesQueue(tempTablesQueue);
      })
      .catch((err) => console.log('err', err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setIsLoading(true);
    getRunningQueue();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const intervalId = setInterval(() => {
      getRunningQueue();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      style={{ background: '#CCE7FF', padding: 16 }}
      className="flex flex-col h-screen"
    >
      <div className="bg-white p-4 h-full">
        <div className="font-inter mb-6">
          <h2 className="font-medium text-2xl text-black2">
            Antrian Pondok Vaksin
          </h2>
        </div>

        <div class="flex space-x-4  overflow-hidden" style={{ height: '90%' }}>
          {/* Left content */}
          <div class="flex-1 flex flex-col justify-center  items-center space-y-4">
            {/* Antrian sekarang */}
            {isLoading ? (
              <>
                <div className="flex justify-center mb-16 animate-spin">
                  <img
                    src={IconLoading}
                    alt="icon loading"
                    className=" justify-center"
                  />
                </div>
              </>
            ) : (
              <>
                {activeQueue.length ? (
                  <>
                    {activeQueue.map((item, index) => (
                      <div className="w-10/12 rounded-md overflow-hidden flex-1 tr animate-AfterLoading">
                        <div
                          className={`${
                            activeQueue.length - 1 !== index
                              ? 'bg-blue4'
                              : 'bg-gray2'
                          } h-full flex flex-col`}
                        >
                          <div
                            className={`${
                              activeQueue.length - 1 !== index
                                ? 'bg-blue2'
                                : 'bg-gray4'
                            } py-3`}
                          >
                            <p className="font-bold text-lg text-black2 font-inter text-center">
                              {activeQueue.length - 1 !== index
                                ? 'Antrian Sekarang'
                                : 'Antrian Selanjutnya'}
                            </p>
                          </div>
                          <div className="flex flex-col h-full justify-center">
                            <p className="font-bold text-9xl text-black font-inter text-center mb-4">
                              {item?.queue_number || '-'}
                            </p>
                            <p className="font-bold text-4xl text-black2 font-inter text-center">
                              {item?.family_member?.fullname || '-'}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <img
                      src={IconAntrianKosong}
                      alt=""
                      className="mx-auto mb-7"
                    />
                    <p className="ml-6">Belum ada antrian baru</p>
                  </>
                )}
              </>
            )}
          </div>

          {/* Right content */}
          <div class="flex-1">
            {/* Table antrian */}
            <div className="flex  h-full">
              <div className="min-w-full h-full overflow-y-auto">
                <table className="w-full border-0">
                  <thead>
                    <tr className="bg-blue4 text-black2">
                      <th className="w-1/12 py-3 px-8 border border-blue4">
                        No Antrian
                      </th>
                      <th className="w-1/12 py-3 px-8 border border-blue4">
                        Nama Pasien
                      </th>
                      <th className="w-2/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr className="text-center">
                        <td colSpan={6} className="whitespace-nowrap p-4">
                          <div className="flex justify-center mb-16 animate-spin">
                            <img
                              src={IconLoading}
                              alt="icon loading"
                              className=" justify-center"
                            />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {tablesQueue.length === 0 && (
                          <tr className="text-center animate-AfterLoading">
                            <td colSpan={6} className="whitespace-nowrap p-4">
                              <img
                                src={IconAntrianKosong}
                                alt=""
                                className="mx-auto mb-7"
                              />
                              <p className="ml-6">Belum ada antrian baru</p>
                            </td>
                          </tr>
                        )}
                        {tablesQueue?.map((list, index) => (
                          <tr
                            className="text-center animate-AfterLoading"
                            key={list}
                          >
                            <td className="border-b border-blue4 px-4 p-4 whitespace-nowrap">
                              <span>{list?.queue_number || '000'}</span>
                            </td>
                            <td className="border-b max-w-md border-blue4 px-4 whitespace-nowrap">
                              <div className="w-full">
                                <p className="truncate">
                                  {list?.family_member?.fullname || '-'}
                                </p>
                              </div>
                            </td>
                            <td className="border-b border-blue4 px-4 whitespace-nowrap">
                              <LabelStatus
                                withOutline
                                type={list.queue_status.name}
                                title={list.queue_status.name}
                              />
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Pagination antrian */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RunningQueue;
