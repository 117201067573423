import { Dropdown } from 'components/atoms/Dropdown/Dropdown';
import { TextContent } from 'components/atoms/Text/TextContent';
import { TitleContent } from 'components/atoms/Text/TitleContent';
import { useState } from 'react';
import { formatNumber } from 'utils';
import { AdditionalPaymentEmpty } from './AdditionalPaymentEmpty';
import { getPaymentChannel } from 'utils';

export const PaymentOrderDetail = ({ data, onSelect }) => {
  const [showList, setShowList] = useState(false);
  const onSelectOption = (e) => {
    onSelect(e.target.id);
    setShowList(false);
  };

  const menuPeymentMethodAdditional = getPaymentChannel();

  const isRefund =
    parseInt(data?.additionalPaymentChannel?.payment_method_id) === 4 &&
    data?.additionalPaymentChannel.amount < 0;

  return (
    <>
      <TitleContent text="Detail Pembayaran Pesanan" />
      {data?.payment_method_id !== 1 ? (
        <>
          <div className="flex justify-between mb-3">
            <TextContent text="Pembayaran Dilunasi" />
            <TextContent
              text={`Rp. ${formatNumber(
                data?.mainPaymentChannel?.amount || 0
              )}`}
            />
          </div>
          <div className="flex justify-between">
            <TextContent text="Metode Pembayaran" />
            <TextContent text={data?.payment_method?.name || '-'} />
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between">
            <TextContent text="Nominal Pemesanan" />
            <TextContent text={`Rp. ${formatNumber(data?.total_price || 0)}`} />
          </div>
        </>
      )}

      <p className="font-inter font-bold text-black text-sm my-4">
        Transaksi Tambahan
      </p>

      <div className="bg-gray2 px-4 py-3 my-4">
        {data?.mainPaymentChannel?.amount === data?.total_price ? (
          <AdditionalPaymentEmpty />
        ) : (
          <>
            <div className="flex justify-between">
              <TextContent text="Tagihan Tambahan" />
              <TextContent
                text={`Rp. ${
                  data?.additionalPaymentChannel?.payment_method_id !== 4 &&
                  data?.additionalPaymentChannel?.amount > 0
                    ? `${formatNumber(data?.additionalPaymentChannel.amount)}`
                    : '0'
                }`}
              />
            </div>
            <div className="flex justify-between mt-3">
              <TextContent text="Refund" />
              <TextContent
                text={`Rp. ${
                  isRefund
                    ? `${formatNumber(data?.additionalPaymentChannel.amount)}`
                    : '0'
                }`}
              />
            </div>
            <div className="flex justify-between mt-3">
              <TextContent
                text={isRefund ? 'Metode Pengembalian' : 'Metode Pembayaran'}
              />
              <Dropdown
                data={data?.additionalPaymentChannel}
                isActive={showList}
                onShowList={() => setShowList(!showList)}
                onCloseList={() => setShowList(false)}
                options={menuPeymentMethodAdditional}
                onSelectOption={onSelectOption}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
