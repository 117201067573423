import Modal from 'componentsV2/Modal';
import React from 'react';

export const ModalSendBroadcast = ({ title, isOpen, onClose, ...props }) => {
  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose} {...props}>
      <div className="">
        <p>Apakah anda akan mengirim notifikasi ?</p>
      </div>
    </Modal>
  );
};
