import { ICChecklistGreenCircle, IconClose } from 'assets';
import { Button } from 'components/atoms';
import React from 'react';

export const ModalFinishedTransaction = ({ orderNumber, onClick, isOpen }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-20 ">
      <div
        className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative rounded-2xl animate-AfterLoading"
        style={{ width: 512, height: 450 }}
      >
        <div
          className="absolute top-7 right-9 cursor-pointer"
          onClick={onClick}
        >
          <img src={IconClose} alt="icon-close.svg" />
        </div>
        <div className="mb-3">
          <img
            src={ICChecklistGreenCircle}
            alt="ic-checklist-circle-green.svg"
          />
        </div>
        <p className="font-inter font-bold text-2xl mb-4">Pesanan Selesai</p>
        <p className="font-inter text-center text-sm mb-8">
          Pesanan {orderNumber || ''} telah diselesaikan. Invoice telah
          diterbitkan
        </p>

        <Button
          isPrimaryOutline
          title="Kembali"
          className="rounded-3 cursor-pointer text-blue1 font-medium"
          onClick={onClick}
        />
      </div>
    </div>
  );
};
