import React from "react";
import { Link } from "react-router-dom";

export default function ButtonLink({
  href,
  className,
  onClick,
  title,
  children,
}) {
  return (
    <Link to={href} className={className} onClick={onClick}>
      {children}
    </Link>
  );
}
