import React, { useEffect, useRef, useState } from 'react';

export const InputDropdown = React.forwardRef(
  (
    {
      type,
      name,
      label,
      className,
      onFocus,
      onBlur,
      onChange,
      onClick,
      value,
      placeholder,
      options,
      fixValue,
      innerRef,
      serviceLocationId,
    },
    ref
  ) => {
    const [showLokasiVaksin, setShowLokasiVaksin] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [valueLokasi, setValueLokasi] = useState('');

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
    // const listStatus = options?.filter(
    //   (option) =>
    //     option.service_location_id === serviceLocationId ||
    //     option.service_location_id === null
    // );

    const wrapperRef = useRef(null);
    const handleClickOutside = (event) => {
      if (wrapperRef && !wrapperRef.current.contains(event.target)) {
        setShowLokasiVaksin(false);
      }

      setIsActive(false);
    };

    return (
      <div
        className={`outline relative border border-black focus-within:border-blue1 focus-within:text-blue1 rounded-3 ${className}`}
        ref={wrapperRef}
      >
        <input
          type={type}
          name={name}
          className={`block p-3 w-full text-sm font-inter appearance-none border-none focus:outline-none focus:ring rounded-3 text-black2 capitalize ${
            showLokasiVaksin
              ? ''
              : 'relative z-10 bg-transparent placeholder-transparent'
          }`}
          onFocus={() => {
            setShowLokasiVaksin(true);
            setIsActive(true);
          }}
          ref={innerRef}
          onChange={onChange}
          // onBlur={() => {
          //   setIsActive(false);
          //   setShowLokasiVaksin(false);
          // }}
          autoComplete="off"
          // value={valueLokasi ? valueLokasi : value}
          value={value?.toLowerCase()}
          placeholder={placeholder ? placeholder : ' '}
          readOnly
        />

        <label
          htmlFor="username"
          className={`absolute top-0 text-sm font-inter bg-white p-3 duration-300 origin-0 rounded-full ${
            isActive ? 'z-10 focus-within:text-blue-600' : '-z-1 text-gray1'
          }`}
        >
          {label}
        </label>

        <div
          className={`bg-white absolute inset-x-0 z-30 max-h-28 overflow-y-scroll side-bar ${
            showLokasiVaksin ? 'mt-2' : 'hidden'
          }`}
        >
          <ul className="font-inter cursor-pointer bg-white">
            {options?.map((option) => (
              <li
                onClick={() => {
                  setShowLokasiVaksin(false);
                  // setValueLokasi(option.name ? option.name : option.fullname);
                  setValueLokasi(
                    option.name || option.fullname || option.brand_name
                  );
                  return fixValue(option);
                }}
                key={option.id}
                className="flex items-center justify-between border border-gray2 rounded-3 px-4 py-3 hover:bg-blue2 focus-within:bg-blue1 capitalize"
              >
                <span className="text-sm text-black2">
                  {/* {option.name ? option.name : option.fullname} */}
                  {option.name?.toLowerCase() ||
                    option.fullname?.toLowerCase() ||
                    option.brand_name?.toLowerCase()}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
);
