import {
  DATA_USER,
  IS_LOADING,
  SET_TOKEN,
  LOKASI_VAKSIN,
  STATUS_ANTRIAN,
  NO_INTERNET,
  DETAIL_PASIEN,
  BTN_DISABLED,
  BTN_PETA_DISABLE,
  BTN_PETA_VAKSINASI,
  DAFTAR_TITIK_TUJUAN,
} from "../type";

const initialState = {
  isLoading: false,
  token: "",
  user: {},
  lokasiVaksin: "",
  statusAntrian: [],
  daftarTitikTujuan: "",
  noInternet: false,
  detailPasien: "",
  btnDisabled: true,
  btnPetaDisable: false,
  btnPetaVaksinasi: -1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.value,
      };
    case DATA_USER:
      return {
        ...state,
        user: action.value,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.value,
      };
    case LOKASI_VAKSIN:
      return {
        ...state,
        lokasiVaksin: action.value,
      };
    case STATUS_ANTRIAN:
      return {
        ...state,
        statusAntrian: action.value,
      };
    case NO_INTERNET:
      return {
        ...state,
        noInternet: action.value,
      };
    case DETAIL_PASIEN:
      return {
        ...state,
        detailPasien: action.value,
      };
    case BTN_DISABLED:
      return {
        ...state,
        btnDisabled: action.value,
      };
    case BTN_PETA_DISABLE:
      return {
        ...state,
        btnPetaDisable: action.value,
      };
    case BTN_PETA_VAKSINASI:
      return {
        ...state,
        btnPetaVaksinasi: action.value,
      };
    case DAFTAR_TITIK_TUJUAN:
      return {
        ...state,
        daftarTitikTujuan: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
