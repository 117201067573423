import { ICCancelCircle, IconClose } from 'assets';
import { Button } from 'components/atoms';
import ButtonFix from 'components/atoms/Button/Button';
import React from 'react';

export const FirstContent = ({ data, onClose, onClickCancel }) => {
  return (
    <>
      <div className="mb-12">
        <img src={ICCancelCircle} alt="ic-cancel-circle-red.svg" />
      </div>
      <p className="font-inter font-bold text-2xl mb-4 text-black">
        Batalkan Pesanan
      </p>
      <p className="font-inter text-center text-sm text-gray1 mb-20">
        Apakah anda yakin akan membatalkan pesanan {data?.order_number || ''}{' '}
        dan seluruh tiketnya?
      </p>
    </>
  );
};
