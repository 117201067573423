import { IconEditUnderline, IconLoading, IconRemove } from 'assets';
import { Button, Modal } from 'components/atoms';
import API from 'config/Axios';
import { getProfileFamily } from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

export default function ProfilKeluarga(props) {
  const [profileFamily, setProfileFamily] = useState('');
  const [editProfileFamily, setEditProfileFamily] = useState({
    show: false,
    isLoading: false,
    familyID: '',
    familyName: '',
  });
  const [deleteFamily, setDeleteFamily] = useState({
    show: false,
    data: '',
  });
  const [showModalTambahAnggota, setShowModalTambahAnggota] = useState(false);
  const [familyID, setFamilyID] = useState(null);
  const [resultFamily, setResultFamily] = useState('');

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const _getProfileFamily = async () => {
    const profile = await dispatch(getProfileFamily(`/staff/families/${id}`));
    setProfileFamily(profile);
  };

  const _updateProfileFamily = async () => {
    setEditProfileFamily({ ...editProfileFamily, isLoading: true });
    const data = {
      _method: 'patch',
      family_name: editProfileFamily.familyName,
    };

    await API.post(`/staff/families/${editProfileFamily.familyID}`, data)
      .then((res) => {
        setEditProfileFamily({ ...editProfileFamily, isLoading: false });
        setEditProfileFamily({ ...editProfileFamily, show: false });
        _getProfileFamily();
      })
      .catch((err) => {
        return err;
      });
  };

  const _getFamily = async () => {
    await API.get(`/staff/families/${familyID}`)
      .then((res) => {
        setResultFamily(res.data.family);
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    const familyId = localStorage.getItem('family_id');
    setFamilyID(parseInt(familyId));
  }, []);

  useEffect(() => {
    _getProfileFamily();
  }, []);

  useEffect(() => {
    if (familyID) {
      _getFamily();
    }
  }, [familyID]);

  if (profileFamily === '') {
    return (
      <div className="h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll flex justify-center items-center">
        <div className="">
          <div className="flex justify-center mb-16 animate-spin">
            <img
              src={IconLoading}
              alt="icon loading"
              className=" justify-center"
            />
          </div>
          <h3 className="font-inter font-bold text-2xl text-center">
            Silahkan Tunggu
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="flex flex-1 overflow-hidden">
        <div className="bg-white pt-8 pb-8 px-16 overflow-y-scroll scrollbar-none border-b-8 border-blue2 w-full">
          <div className="content flex justify-between border-b-2 pb-5 mb-8 border-gray2 font-inter">
            <h2 className="font-bold text-3xl text-black">Profil Keluarga</h2>
          </div>

          <div className="block lg:flex justify-between border p-12 rounded-3 shadow-md mb-5">
            <div className="flex items-center">
              <div className="w-28 h-28 rounded-full bg-blue1 flex justify-center items-center">
                <svg
                  width="61"
                  height="43"
                  viewBox="0 0 61 43"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.3757 21.5007C27.2461 21.5007 32.0215 16.7252 32.0215 10.8548C32.0215 4.9844 27.2461 0.208984 21.3757 0.208984C15.5052 0.208984 10.7298 4.9844 10.7298 10.8548C10.7298 16.7252 15.5052 21.5007 21.3757 21.5007ZM21.3757 6.29232C23.9002 6.29232 25.9382 8.33024 25.9382 10.8548C25.9382 13.3794 23.9002 15.4173 21.3757 15.4173C18.8511 15.4173 16.8132 13.3794 16.8132 10.8548C16.8132 8.33024 18.8511 6.29232 21.3757 6.29232ZM21.5277 36.709H8.5094C11.5207 35.1882 16.7219 33.6673 21.3757 33.6673C21.7102 33.6673 22.0752 33.6977 22.4098 33.6977C23.444 31.4773 25.2386 29.6523 27.3981 28.1923C25.1777 27.7969 23.079 27.584 21.3757 27.584C14.2582 27.584 0.0839844 31.1427 0.0839844 38.2298V42.7923H21.3757V38.2298C21.3757 37.7127 21.4365 37.1957 21.5277 36.709ZM44.1882 29.1048C38.5915 29.1048 27.459 32.1769 27.459 38.2298V42.7923H60.9173V38.2298C60.9173 32.1769 49.7848 29.1048 44.1882 29.1048ZM47.8686 23.569C50.1802 22.2611 51.7923 19.7973 51.7923 16.9382C51.7923 14.9214 50.9912 12.9873 49.5651 11.5612C48.1391 10.1351 46.2049 9.33399 44.1882 9.33399C42.1714 9.33399 40.2373 10.1351 38.8112 11.5612C37.3851 12.9873 36.584 14.9214 36.584 16.9382C36.584 19.7973 38.1961 22.2611 40.5077 23.569C41.6027 24.1773 42.8498 24.5423 44.1882 24.5423C45.5265 24.5423 46.7736 24.1773 47.8686 23.569Z"
                    fill="white"
                  />
                </svg>
              </div>

              <div className="ml-12 font-inter">
                <div className="blocl lg:flex items-center  mb-3">
                  {/* <h2 className="font-bold text-2xl text-black mr-10 whitespace-nowrap">
                    Keluarga #{profileFamily.id}
                  </h2> */}
                  <h2 className="font-bold text-2xl text-black mr-10 whitespace-nowrap capitalize">
                    {profileFamily.family_name}
                  </h2>

                  <Button
                    title="Edit Profil"
                    className="font-inter font-bold text-blue1"
                  />
                </div>
                <p className="font-inter font-medium text-base text-gray5 mb-2">
                  Pemilik Akun : {profileFamily.registrant.fullname}
                </p>
                <p className="font-inter font-medium text-base text-gray5">
                  Nomor Telpon : {profileFamily.registrant.user.phone_number}
                </p>
              </div>
            </div>

            <div className="flex justify-end lg:justify-start mt-6 lg:mt-0 lg:w-max lg:flex-col xl:block lg:items-end">
              {/* Update Profile Family */}
              <Button
                type="button-icon"
                icon={IconEditUnderline}
                className="border-2 border-blue1 p-1.5 rounded-3 mr-3 lg:mb-2 lg:mr-0 xl:mr-3 w-max"
                onClick={() =>
                  setEditProfileFamily({
                    show: true,
                    familyID: profileFamily.id,
                    familyName: profileFamily.family_name,
                  })
                }
              />

              {/* Modal Update  Profile Family */}
              {editProfileFamily.show && (
                <div className="bg-black bg-opacity-30 absolute inset-0 flex justify-center items-center">
                  <div className="bg-white w-1/4 p-8">
                    <div className="flex  justify-between">
                      <p className="text-2xl font-bold font-inter mb-4">
                        Edit Keluarga
                      </p>

                      {/* Icon Close */}
                      <svg
                        className="cursor-pointer"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => setEditProfileFamily(false)}
                        s
                      >
                        <path
                          d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
                          fill="#C8C8C8"
                        />
                      </svg>
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor="edit-nama-keluarga"
                        className="font-medium font-inter text-black"
                      >
                        Nama Keluarga
                      </label>
                      <input
                        id="edit-nama-keluarga"
                        type="text"
                        className="mt-2 rounded-3 focus:ring focus:ring-blue1 focus:ring-opacity-30"
                        value={editProfileFamily.familyName}
                        onChange={(e) =>
                          setEditProfileFamily({
                            ...editProfileFamily,
                            familyName: e.target.value,
                          })
                        }
                        autoCapitalize="off"
                      />

                      <button
                        disabled={editProfileFamily?.isLoading}
                        className={`${
                          editProfileFamily?.isLoading ? 'bg-gray1' : 'bg-blue1'
                        } w-max px-5 py-2 rounded-3 mx-auto mt-4 text-white font-inter font-medium`}
                        onClick={() => _updateProfileFamily()}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Remove Profile Family */}
              <Button
                type="button-icon"
                icon={IconRemove}
                className="border-2 border-red1 p-1.5 rounded-3 w-max"
                onClick={() =>
                  setDeleteFamily({
                    show: true,
                    data: profileFamily,
                  })
                }
              />

              {/* Modal Delete  Profile Family */}
              {deleteFamily.show && (
                <Modal
                  data={deleteFamily.data}
                  type="hapus-produk-dokter"
                  isDeleteFamily
                  closeModal={() => setDeleteFamily(false)}
                  // value={(value) => Antrian(value)}
                  tes={() => history.goBack()}
                />
              )}
            </div>
          </div>

          <div className="block xl:flex ">
            <div className="flex-1 py-7 pl-10 pr-6 border  rounded-3 shadow-md mb-6 xl:mb-0">
              <div className="font-inter flex justify-between border-b pb-2.5 mb-9">
                <h2 className="font-bold">Daftar Anggota Keluarga</h2>
                <Button
                  onClick={() => setShowModalTambahAnggota(true)}
                  title="+ Tambah Anggota Keluarga"
                  className="text-blue1 font-normal text-sm"
                />
              </div>

              {profileFamily?.family_members?.map((family, i) => (
                <div
                  className="font-inter flex justify-between items-center mb-4"
                  key={family.id}
                >
                  <div className="">
                    <p className="font-bold text-black2 mb-1">
                      {family.fullname}
                    </p>
                    <p className="font-normal text-sm text-gray1 capitalize">
                      {family.gender} &bull; Usia {family.age.y} tahun{' '}
                      {family.age.m} bulan &bull; {family.family_position.name}
                    </p>
                  </div>
                  <Link
                    className="text-blue1 font-semibold text-sm"
                    to={`/dashboard/profil/${family.id}`}
                  >
                    Lihat Profil
                  </Link>
                </div>
              ))}
            </div>

            {/* <div className="flex-1 py-7 px-10 border  rounded-3 shadow-md ml-0 xl:ml-3">
              <div className="font-inter flex justify-between border-b pb-2.5 mb-9">
                <h2 className="font-bold">Riwayat Vaksin Terakhir</h2>
                <Button
                  title="+ Tambah Riwayat"
                  className="text-blue1 font-normal text-sm"
                />
              </div>

              <div className="font-inter flex justify-between items-center mb-4">
                <div className="">
                  <p className="font-bold text-black2 mb-1">Muhammad Alwi</p>
                  <p className="font-normal text-sm text-gray1">
                    Vaksin Polio - Vaksin A - ke 1
                  </p>
                </div>
                <p className="font-normal text-sm text-gray1">
                  12 Desember 2020
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="bg-white py-4 px-7">
        <Button
          isPrimaryOutline
          isBtnOutlineSecondary
          title="Kembali"
          className="rounded-3"
          onClick={() => props.history.goBack()}
        />
      </div>

      {showModalTambahAnggota && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-30">
          <Modal
            type="form-daftar-keluarga"
            data={resultFamily}
            closeModal={() => setShowModalTambahAnggota(false)}
          />
        </div>
      )}
    </div>
  );
}
