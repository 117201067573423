import React from "react";

export default function ButtonOutlineSecondary({
  title,
  className,
  onClick,
  isPrimary,
  isGreen,
  disabled,
}) {
  const variant = [];
  if (isPrimary)
    variant.push("border-blue1 hover:border-blue3 text-blue1 hover:text-blue3");
  if (isGreen)
    variant.push(
      "border-green1 text-green1 hover:text-green-200 hover:border-green-200"
    );

  return (
    <button
      disabled={disabled}
      className={`border px-7 py-3 font-inter font-bold text-sm rounded-3 ${variant} ${
        className ? className : ""
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
}
