import { IconCloseRed } from 'assets';
import { Button, Modal } from 'components/atoms';
import React from 'react';
import { formatNumber } from 'utils';

export const CardVaccine = ({
  data,
  detailPasien,
  dataModalBatch,
  onShowModalBatch,
  onSetPatientDetail,
  onGetPatientDetail,
  onDeletedVaccine,
}) => {
  return (
    <div className="px-6 py-4 bg-gray2 rounded-3 mb-4">
      <div className="flex justify-between">
        <div className="flex items-center flex-wrap">
          <p className="font-inter font-normal text-sm text-black2">
            {`${data?.vaccine?.brand_name}`} &nbsp;
          </p>

          {data.batch !== null && (
            <p className="font-inter font-normal text-sm text-black2 mr-4">
              - Nomor Batch :{' '}
              <span className="font-inter font-bold text-sm text-black2">
                {data?.batch || '-'}
              </span>
            </p>
          )}

          <p className="font-inter font-normal text-sm text-green1 mr-4">
            {`Rp. ${formatNumber(data.price)}`}
          </p>
        </div>

        <div className="flex">
          {data.batch === null && (
            <Button
              id={data.id}
              title="+ Nomor Batch"
              className="text-blue1 text-sm font-semibold mr-4"
              alt="hapus"
              onClick={() => onShowModalBatch(data)}
            />
          )}
          {dataModalBatch.show && dataModalBatch.id === data.id && (
            <Modal
              modalId={dataModalBatch.id}
              type="tambah-nomor-batch"
              closeModal={() => onShowModalBatch(false)}
              fixData={async (value, id) => {
                const newData = { ...detailPasien };

                const filterVaksin = newData.vaccinations.map((vaksin, i) => {
                  if (id === vaksin.id) {
                    const tempVaksin = { ...vaksin };
                    tempVaksin.batch = value.toString();
                  }
                  return vaksin;
                });

                newData.vaccinations = filterVaksin;

                onSetPatientDetail(newData);

                if (data.id === id) {
                  data.batch = value;
                }

                await onGetPatientDetail(newData);
              }}
            />
          )}

          <Button
            id={data.id}
            type="button-icon"
            icon={IconCloseRed}
            className=""
            alt="hapus"
            onClick={async () => {
              const newData = { ...detailPasien };

              const filterVaksin = newData.vaccinations?.filter((vaksin, i) => {
                if (Number.isInteger(vaksin.id)) {
                  if (data.id === vaksin.id) {
                    onDeletedVaccine(vaksin.id);
                  }
                }
                return data.id !== vaksin.id;
              });

              newData.vaccinations = filterVaksin;

              onSetPatientDetail(newData);
              await onGetPatientDetail(newData);
            }}
          />
        </div>
      </div>

      <div className="flex justify-between datas-end mt-3">
        <div className="">
          {data.vaccine_types.map((jenis, i) => {
            return (
              <p
                className="font-inter font-normal text-sm text-black2"
                key={i}
              >{`${jenis?.name || '-'} - ${
                jenis.pivot.period !== null && jenis.pivot.period !== undefined
                  ? `Ke - ${jenis.pivot.period}`
                  : 'Ke - 1'
              }`}</p>
            );
          })}
        </div>

        {/* <div className="flex justify-between mt-5">
    <Button
      title="+ Edit Periode"
      className="text-blue1 text-sm font-semibold"
    />
  </div> */}
      </div>
    </div>
  );
};
