import { IconLeftArrow } from 'assets';
import useOutsideClick from 'Hooks/useOutsideClick/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';

export const Dropdown = ({
  data,
  isActive,
  onShowList,
  onCloseList,
  options,
  onSelectOption,
}) => {
  const ref = useRef();
  const [paymentMethodId, setPaymentMethodId] = useState(
    data?.payment_channel_id
  );
  const [valueOption, setValueOption] = useState(data?.payment_channel?.name);

  useOutsideClick(ref, () => {
    onCloseList();
  });

  const onSelectPayment = (e) => {
    setPaymentMethodId(e.target.id);
    return onSelectOption(e);
  };

  useEffect(() => {
    const filterData = options?.filter(
      (item) => item.id === parseInt(paymentMethodId)
    )[0];

    setValueOption(filterData);
  }, [paymentMethodId]);

  return (
    <div className="accordion relative" ref={ref}>
      <div
        className={`flex justify-between items-center w-full focus:outline-none side-menu cursor-pointer`}
        onClick={onShowList}
      >
        <div className="flex justify-end">
          <p className="xl:block font-inter font-normal text-sm text-black2 capitalize mr-4">
            {valueOption?.name?.toLowerCase() || 'Pilih Metode Pembayaran'}
          </p>
          <img
            src={IconLeftArrow}
            alt={IconLeftArrow}
            className={`transform arrowLeft transition-transform duration-200 ${
              isActive ? '-rotate-90' : ''
            }`}
          />
        </div>
      </div>

      <div className="absolute bg-white w-max right-0 mt-1 rounded-md shadow-xl max-h-48 overflow-auto side-bar">
        <div
          className={`origin-top overflow-hidden font-inter font-medium text-sm ${
            isActive
              ? 'animate-SideBarListAnimation py-2'
              : 'h-0 invisible opacity-0'
          }`}
        >
          {options.map((option, i) => {
            return (
              <p
                key={option.id}
                className={`hover:underline block hover:text-blue1 xl:block font-inter font-normal  text-sm text-black2 capitalize border-b border-gray-100 w-48 p-4 cursor-pointer`}
                id={option.id}
                onClick={(e) => onSelectPayment(e)}
              >
                {option.name.replace('_', ' ')?.toLowerCase()}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};
