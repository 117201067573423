import React from 'react';
import ButtonOutlineSecondary from './ButtonOutlineSecondary';
import ButtonPrimary from './ButtonPrimary';
import ButtonIcon from './ButtonIcon';
import ButtonLink from './ButtonLink';

export default function Button({
  id,
  name,
  alt,
  isLoading,
  title,
  className,
  onClick,
  type,
  href,
  icon,
  isPrimary,
  isBtnOutlineSecondary,
  isPrimaryOutline,
  isGreenOutline,
  isDisabled,
  disabled,
}) {
  if (isPrimary) {
    return (
      <ButtonPrimary
        isDisabled={isDisabled}
        title={title}
        className={className}
        onClick={onClick}
        type={type}
      />
    );
  }
  if (isBtnOutlineSecondary) {
    return (
      <ButtonOutlineSecondary
        disabled={disabled}
        isPrimary={isPrimaryOutline}
        isGreen={isGreenOutline}
        title={title}
        className={className}
        onClick={onClick}
      />
    );
  }

  if (type === 'isInternalLink') {
    return (
      <ButtonLink
        className={className}
        onClick={onClick}
        title={title}
        href={href}
      />
    );
  }

  if (type === 'button-icon') {
    return (
      <ButtonIcon
        id={id}
        icon={icon}
        className={className}
        onClick={onClick}
        alt={alt}
      />
    );
  }

  return (
    <button
      id={id}
      name={name}
      // className={
      //   isDisabled
      //     ? `rounded-10 bg-gray1 px-3 w-full py-4 font-inter text-sm text-white font-medium`
      //     : className
      // }
      className={className}
      onClick={onClick}
      disabled={isLoading || isDisabled}
    >
      {isLoading ? 'Loading...' : title}
    </button>
  );
}
