import { getJenisVaksin } from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

const customStyles = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
  }),
  menu: (provided) => ({ ...provided, zIndex: 30 }),
  indicatorSeparator: () => (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'none',
  }),
};

export const InputSelect = React.forwardRef(
  (
    {
      id,
      type,
      name,
      label,
      className,
      onFocus,
      onBlur,
      onChange,
      onClick,
      value,
      placeholder,
      readOnly,
      defaultValue,
    },
    ref
  ) => {
    const [options, setOptions] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [values, setValues] = useState([]);
    const [nilaiAwal, setNilaiAwal] = useState('');

    const dispatch = useDispatch();

    const handleChange = (selectedOptions) => {
      setValues(selectedOptions);
      return value(selectedOptions);
    };

    const handleOnBlur = () => {
      values.length === 0 ? setIsActive(false) : setIsActive(true);
    };

    const _getTipeVaksin = async () => {
      const tipe = await dispatch(getJenisVaksin('/staff/vaccine-types'));

      const temp = tipe.map((item) => {
        return {
          id: item.id,
          vaccine_category_id: item.vaccine_category_id,
          value: item.name,
          label: item.name,
        };
      });

      setOptions(temp);
    };

    useEffect(() => {
      _getTipeVaksin();

      const nilai = defaultValue?.map((item) => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          vaccine_category_id: item.vaccine_category_id,
        };
      });

      if (nilai !== []) {
        setIsActive(true);
      }

      setNilaiAwal(nilai);
    }, []);

    if (options === [] || nilaiAwal === '') {
      return <p>Loading...</p>;
    }

    return (
      <div
        className={`outline relative border border-black focus-within:border-blue1 focus-within:text-blue1 rounded-3 p-1 ${className}`}
      >
        <Select
          isMulti
          name="colors"
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={customStyles}
          placeholder=""
          defaultValue={nilaiAwal}
          inputId="jenis-vaksin"
          onFocus={() => setIsActive(true)}
          onBlur={(e, a) => handleOnBlur(e, a)}
          onChange={handleChange}
        />

        <label
          htmlFor="username"
          className={`absolute top-0 text-sm font-inter bg-white p-3 duration-300 origin-0 rounded-full break-words ${
            isActive
              ? 'transform -translate-y-3 z-0 ml-3 px-1 py-0'
              : '-z-1 text-gray1'
          } `}
        >
          {label}
        </label>
      </div>
    );
  }
);
