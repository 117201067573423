import { ICCancelCircle, ICChecklistGreenCircle, IconClose } from 'assets';
import { Button } from 'components/atoms';
import ButtonFix from 'components/atoms/Button/Button';
import API from 'config/Axios';
import React from 'react';
import { useState } from 'react';
import { CancelTransaction } from 'services/CancelTransaction';
import { ConfirmOrderPayment } from 'services/ConfirmOrderPayment';
import { FinishTransaction } from 'services/FinishTransaction';
import { getPaymentChannel, getPaymentMethod } from 'utils';
import { GeneratedPayloadConfirmPayment } from '../../utils/GeneratedPayloadConfirmPayment';
import { FirstContent } from './FirstContent';
import { FormCancelTransaction } from './FormCancelTransaction';

export const ModalCancelTransaction = ({
  data,
  isOpen,
  onClose,
  onSuccessCancel,
}) => {
  const [isShowFormCancel, setIsShowFormCancel] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const onShowFormCancel = () => setIsShowFormCancel(true);
  const onCloseFormCancel = () => setIsShowFormCancel(false);
  const onChangeRefundAmount = (event) =>
    setRefundAmount(event.floatValue || 0);

  const onCancelTransaction = () => {
    setIsLoading(true);
    const allPeymentChannel = getPaymentChannel();
    const paymentChannelId = allPeymentChannel?.filter(
      (item) => item.name === 'TUNAI'
    )?.[0]?.id;

    const allPaymentMethod = getPaymentMethod();
    const refundId = allPaymentMethod?.filter(
      (item) => item.name === 'Refund'
    )?.[0]?.id;

    const generatedTempPayloadConfirm = GeneratedPayloadConfirmPayment({
      data,
    });

    const payloadConfirmPayment = {
      ...generatedTempPayloadConfirm,
      additionalPaymentChannel: {
        ...generatedTempPayloadConfirm.additionalPaymentChannel,
        payment_channel_id: paymentChannelId,
        payment_method_id: refundId,
        amount: -Math.abs(parseInt(refundAmount)),
      },
    };

    CancelTransaction(data?.id)
      .then(() => {
        ConfirmOrderPayment(payloadConfirmPayment).then(() =>
          onSuccessCancel().finally(() => {
            setIsLoading(false);
            setIsShowFormCancel(false);
            onClose();
          })
        );
      })
      .catch(() => alert('Gagal Membatalkan Transaksi'))
      .finally(() => {
        setIsLoading(false);
        setIsShowFormCancel(false);
        onClose();
      });
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-20 ${
        !isOpen ? 'hidden' : ''
      }`}
    >
      <div
        className="w-auto max-h-full bg-white px-14 flex flex-col justify-center items-center relative rounded-2xl animate-AfterLoading"
        style={{ width: 512, height: 450 }}
      >
        <div
          className="absolute top-7 right-9 cursor-pointer"
          onClick={onClose}
        >
          <img src={IconClose} alt="icon-close.svg" />
        </div>
        {!isShowFormCancel ? (
          <FirstContent
            data={data}
            onClose={onClose}
            onClickCancel={onShowFormCancel}
          />
        ) : (
          <FormCancelTransaction
            data={data}
            onChangeRefundAmount={onChangeRefundAmount}
          />
        )}

        <div className="flex justify-between w-full">
          <Button
            isPrimaryOutline
            title="Kembali"
            className="rounded-3 cursor-pointer text-blue1 font-medium w-32 hover:text-blue-300"
            onClick={!isShowFormCancel ? onClose : onCloseFormCancel}
          />

          <ButtonFix
            isPrimary
            className="py-4 px-6 rounded-10"
            isDisabled={refundAmount === 0 && isShowFormCancel}
            onClick={!isShowFormCancel ? onShowFormCancel : onCancelTransaction}
            isLoading={isLoading}
          >
            {!isShowFormCancel ? 'Batalkan Pesanan' : 'Selesaikan Pesanan'}
          </ButtonFix>
        </div>
      </div>
    </div>
  );
};
