import API from 'config/Axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IconLogout, IconNoPhoto, JSONSubMenu } from '../../../assets';
import { Button, Input, Sidemenu } from '../../atoms';

export default function Sidebar(props) {
  const history = useHistory();
  const [data, setData] = useState(JSONSubMenu.sideData);
  const [isActive, setIsActive] = useState(false);
  const [showModalScanner, setShowModalScanner] = useState(false);
  const [tokenCheckin, setTokenCheckin] = useState('');

  const handleClickCheckin = async () => {
    const sendData = {
      checkin_token: tokenCheckin,
    };

    await API.patch('/staff/queues/checkin', sendData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  };

  return (
    <div className="w-max">
      {/* START : Sidebar */}

      <aside className="w-full bg-white h-screen pt-11 px-8 pb-10 flex flex-col justify-between overflow-y-scroll side-bar">
        <div className="w-max">
          <div
            className="h-7 w-7 mb-4 xl:hidden cursor-pointer"
            onClick={() => setIsActive(!isActive)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isActive ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </div>
          <h2
            className={`${
              isActive ? '' : 'hidden'
            } xl:flex  font-inter font-bold text-3xl text-blue1`}
          >
            Pondok <span className="text-gray1">Vaksin</span>
          </h2>

          {/* START : Side Foto Profile */}
          <div
            className={`profile mt-10 flex items-center pb-10 mb-5 border-b-2 xl:-mx-8 xl:px-8 `}
          >
            <div className="w-8 h-8 rounded-full overflow-hidden">
              <img
                src={IconNoPhoto}
                alt="Foto Profile Admin"
                className="object-cover w-full h-full"
              />
            </div>

            <div className={`${isActive ? '' : 'hidden'} xl:block desc ml-3`}>
              <p className="font-inter font-bold text-sm text-black2">
                {localStorage.getItem('name') || 'asd'}
              </p>
              <p className="font-inter font-medium text-xs text-black2 capitalize">
                {`${
                  props.role === 'admin'
                    ? 'Admin'
                    : `${props.role || ''} - ${props.ruangBertugas || ''}`
                }`}
              </p>
            </div>
          </div>
          {/* END : Side Foto Profile */}

          {/* START : Side Menu */}
          {data.map((item, i) => (
            <div key={i}>
              <Sidemenu
                isButton={item.isButton}
                withSubMenu={item.withsubmenu}
                listSubMenu={item.submenu}
                showTitle={isActive}
                title={item.title}
                isActive={item.active}
                link={item.link}
                onClick={() => {
                  if (item.isButton) {
                    window.open(item.link);
                    // item.showModal = !item.showModal;
                    // console.log("item.showModal", item.showModal);
                    // setShowModalScanner(!item.showModal);
                  } else {
                    const newData = [...data];
                    newData.map((data, index) =>
                      index === i
                        ? newData[index].active
                          ? (newData[index].active = false)
                          : (data.active = true)
                        : (data.active = false)
                    );
                    setData(newData);
                  }
                }}
                {...props}
              />
            </div>
          ))}
          {/* END : Side Menu */}
        </div>

        <div
          className="logout flex cursor-pointer"
          onClick={() => {
            localStorage.clear();
            history.replace('/');
          }}
        >
          <img src={IconLogout} alt="Icon Logout" />
          <p
            className={`${
              isActive ? '' : 'hidden'
            } xl:block ml-3 text-gray1 font-inter font-semibold`}
          >
            Logout
          </p>
        </div>
      </aside>
      {/* END : Sidebar */}

      {showModalScanner && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-10">
          <div className="bg-white p-8 rounded-3" style={{ width: 420 }}>
            <div className="flex justify-between mb-8">
              <h2 className="font-inter font-semibold text-lg">Scan Barcode</h2>
              <div className="">
                <svg
                  className="cursor-pointer"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setShowModalScanner(false)}
                >
                  <path
                    d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
                    fill="#C8C8C8"
                  />
                </svg>
              </div>
            </div>

            <div className="">
              <Input
                withOutline
                name="Token Checkin"
                label="Token Checkin"
                className="mb-6"
                value={tokenCheckin}
                onChange={(e) => setTokenCheckin(e.target.value)}
              />
            </div>

            <div className="flex justify-center">
              <Button
                isPrimary
                title="Checkin"
                className="rounded-3 px-14"
                onClick={() => handleClickCheckin()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
