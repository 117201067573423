import React from "react";

export default function Scanner() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="682.667"
      // height="682.667"
      version="1"
      viewBox="0 0 512 512"
      style={{ width: 24, marginLeft: 2 }}
    >
      <path
        className="fill-current"
        d="M105 4591c-46-21-79-54-94-94-7-18-11-161-11-401 0-366 0-372 23-416 60-119 208-127 291-16 20 26 21 43 24 316l3 289 289 3c275 3 290 4 317 24 15 11 38 36 51 54 47 69 18 186-58 233-34 22-43 22-415 25-353 2-383 1-420-17zM4219 4600c-73-22-119-87-119-167 0-55 24-101 73-137 27-20 42-21 317-24l289-3 3-289c3-273 4-290 24-316 45-61 113-89 181-74 51 12 84 39 111 90 22 44 22 50 22 416 0 240-4 383-11 401-15 40-48 73-94 94-37 17-70 19-405 18-201 0-377-4-391-9zM685 3918c-3-7-4-623-3-1368l3-1355 168-3 167-2v2740H855c-123 0-167-3-170-12zM1370 2905V1880h340l-2 1023-3 1022-167 3-168 2V2905zM2050 2905V1880h340l-2 1023-3 1022-167 3-168 2V2905zM2735 3918c-3-7-4-623-3-1368l3-1355 168-3 167-2v2740h-165c-123 0-167-3-170-12zM3417 3923c-4-3-7-465-7-1025V1880h340v2050h-163c-90 0-167-3-170-7zM4100 2560V1190l168 2 167 3v2730l-167 3-168 2V2560zM131 1530c-48-11-83-40-108-90-23-44-23-50-23-417 0-410 1-417 59-466 55-46 80-49 476-45 361 3 371 4 405 25 76 47 105 164 58 233-13 18-36 43-51 54-27 20-42 21-318 24l-289 3v274c0 170-4 284-11 302-28 76-116 121-198 103zM1370 1365v-175l168 2 167 3v340l-167 3-168 2v-175zM2050 1365v-175l168 2 167 3 3 173 2 172h-340v-175zM3414 1527c-2-7-3-85-2-172l3-160 168-3 167-2v350h-165c-126 0-167-3-171-13zM4911 1530c-39-9-76-35-105-74-20-26-21-43-24-316l-3-289-289-3c-275-3-290-4-317-24-15-11-38-36-50-54-48-69-19-186 57-233 34-21 44-22 405-25 396-4 421-1 476 45 58 49 59 57 59 463 0 391-2 413-48 463-36 40-103 60-161 47z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}
