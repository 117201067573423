import API from 'config/Axios';
import React, { useEffect, useState } from 'react';

const useGetListMessages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [counterRefetch, setCounterRefetch] = useState(0);

  useEffect(() => {
    if (!counterRefetch) {
      setIsLoading(true);
    }
    API.get(`/staff/broadcast-messages`)
      .then((res) => {
        return setResponse({
          data: res?.data?.broadcastMessages || [],
          error: false,
        });
      })
      .catch(() => {
        return setResponse({
          data: [],
          error: true,
        });
      })
      .finally(() => setIsLoading(false));
  }, [counterRefetch]);

  return {
    data: response,
    isLoading,
    refetch: () => setCounterRefetch((prev) => prev + 1),
  };
};

export default useGetListMessages;
