import { getAntrianKlinik } from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconAntrianKosong, IconLoading, IconSearch } from '../../../assets';
import { formatDate, formatJam } from '../../../utils';
import { Button, Input, LabelStatus, Modal } from '../../atoms';

export default function AntrianKlinik(props) {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState({ show: false, lokasiVaksin: '' });
  const [idPasien, setIdPasien] = useState('');
  const [statusAntrian, setStatusAntrian] = useState('');
  const [listAntrian, setListAntrian] = useState([]);
  const [checked, setChecked] = useState(true);
  const [showBatalkan, setShowBatalkan] = useState(false);
  const noInternet = useSelector((state) => state.noInternet);
  const [listSearch, setListSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const Antrian = (value) => {
    return setStatusAntrian(value);
  };

  const _getAntrianKlinik = async () => {
    setIsLoading(true);
    if (localStorage.getItem('role') !== 'admin') {
      const list = await dispatch(
        getAntrianKlinik('/staff/queues/running?', 1, checked ? 1 : 0)
      );
      setListSearch(list);
      setListAntrian(list);
      return setIsLoading(false);
    }

    const list = await dispatch(
      getAntrianKlinik('/staff/queues/running?', 1, checked ? 1 : 0)
    );
    setListSearch(list);
    setListAntrian(list);
    return setIsLoading(false);
  };

  useEffect(() => {
    _getAntrianKlinik();

    return () => {
      document.removeEventListener('mouseleave', _getAntrianKlinik);
    };
  }, [checked]);

  useEffect(() => {
    setDate(formatDate(new Date()));
    const timer = setInterval(() => {
      setTime(formatJam(new Date()));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="flex flex-col h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll">
      {/* START : Header Content */}
      <div className="content flex justify-between border-b-2 pb-10 mb-8 border-gray2">
        <div className="font-inter">
          <h2 className="font-bold text-3xl text-black2">
            Antrian Klinik Hari Ini
          </h2>
        </div>
        <div className="font-inter text-right">
          <h4 className="font-bold text-black2">{date}</h4>
          <p className="font-normal text-black2">{time}</p>
        </div>
      </div>
      {/* END : Header Content */}

      <div className="flex items-center justify-between mb-6 ">
        <div className="font-inter">
          <h2 className="font-bold text-2xl text-black2">Daftar Antrian</h2>
        </div>

        <div className="block lg:flex ">
          {localStorage.getItem('role') !== 'admin' && (
            <label className="inline-flex items-center mr-7 px-4 rounded-3 py-2 bg-gray2 mb-4 lg:mb-0 w-full lg:w-max">
              <input
                type="checkbox"
                className="form-checkbox text-blue1 rounded-3"
                defaultChecked={checked}
                onChange={() => setChecked(!checked)}
              />
              <span className="ml-2 text-sm text-black2">
                Tampilkan Sesuai Tugas
              </span>
            </label>
          )}

          <Input
            withIcon
            type="text"
            name="search"
            value={search}
            className="px-2 py-1"
            // onChange={(e) => setSearch(e.target.value)}
            placeholder="Cari data..."
            icon={IconSearch}
            onChange={(e) => {
              const filter = listSearch?.filter((item) => {
                return (
                  item.family_member.fullname
                    .toLowerCase()
                    .match(e.target.value.toLowerCase()) ||
                  item.registration_code
                    .toLowerCase()
                    .match(e.target.value.toLowerCase())
                );
              });

              //
              setSearch(e.target.value);
              setListAntrian(filter);
            }}
          />

          <Button
            title="+ Tambah Antrian"
            className="bg-blue1 px-7 py-3 font-inter font-bold text-sm text-white rounded-3 mt-4 lg:mt-0  w-full lg:w-max lg:ml-7 hover:bg-blue3"
            onClick={() =>
              props.history.push('/dashboard/antrian-klinik/tambah-antrian')
            }
          />
        </div>
      </div>

      <div className="flex-1">
        <div className="h-full min-w-full overflow-x-scroll">
          <table className="w-full">
            <thead>
              <tr className="bg-blue4 text-black2">
                <th className="w-1/12 py-3 px-8 border border-blue4">
                  No Antrian
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4">
                  No Registrasi
                </th>
                <th className="w-2/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Nama Pasien
                </th>
                <th className="w-2/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Vaksinator
                </th>
                <th className="w-2/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Status Antrian
                </th>
                <th className="w-4/12  border border-blue4 whitespace-nowrap  py-3 px-8">
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr className="text-center animate-AfterLoading">
                  <td colSpan={6} className="whitespace-nowrap p-4">
                    <img
                      src={IconLoading}
                      alt=""
                      className="mx-auto mb-7 animate-spin w-16 h-16 mt-4"
                    />
                    <p className="animate-pulse">Loading...</p>
                  </td>
                </tr>
              )}
              {listAntrian.length === 0 && !isLoading && (
                <tr className="text-center">
                  <td colSpan={6} className="whitespace-nowrap p-4">
                    <img
                      src={IconAntrianKosong}
                      alt=""
                      className="mx-auto mb-7"
                    />
                    <p className="ml-6">Belum ada antrian baru</p>
                  </td>
                </tr>
              )}
              {listAntrian?.map((list) => (
                <tr className="text-center" key={list.id}>
                  <td className="border border-blue4 px-4 whitespace-nowrap">
                    {list.queue_number || '000'}
                  </td>
                  <td className="border border-blue4 px-4 whitespace-nowrap">
                    {list.registration_code}
                  </td>
                  <td className="border border-blue4 px-4 whitespace-nowrap">
                    {list.family_member?.fullname}
                  </td>
                  <td className="border border-blue4 px-4 whitespace-nowrap">
                    {list?.order?.doctor?.fullname || '-'}
                  </td>
                  <td className="border border-blue4 px-4 whitespace-nowrap">
                    <LabelStatus
                      withOutline
                      type={list.queue_status.name}
                      title={list.queue_status.name}
                    />
                  </td>
                  <td className="border border-blue4 text-left px-6 py-3 flex flex-wrap">
                    <button
                      className="bg-blue1 text-white border rounded-3 hover:bg-blue3 font-inter font-medium text-xs focus:bg-blue5 focus:outline-none whitespace-nowrap px-4  py-2 mb-1 mr-2"
                      onClick={() => {
                        setIdPasien(list.id);

                        setShowModal({
                          show: true,
                          lokasiVaksin: list.order.service_location_id,
                        });
                      }}
                    >
                      Update Status
                    </button>

                    <Link
                      className="bg-blue1 text-white border rounded-3 hover:bg-blue3 font-inter font-medium text-xs focus:bg-blue5 focus:outline-none px-4 py-2 mb-1 mr-2"
                      to={`/dashboard/detail-pasien-vaksin/${list.id}`}
                    >
                      Detail
                    </Link>

                    {list.queue_status.id <= 2 ? null : (
                      <Link
                        className="bg-blue1 text-white px-4 py-2 border rounded-3 hover:bg-blue3 font-inter font-medium text-xs focus:bg-blue5 focus:outline-none mr-2 mb-1"
                        to={`/dashboard/pengukuran/${list.id}`}
                      >
                        Pengukuran
                      </Link>
                    )}

                    <button
                      className="bg-red1 text-white border rounded-3 hover:bg-red-400 font-inter font-medium text-xs px-4 py-2 mb-1"
                      onClick={() => {
                        setIdPasien(list.id);
                        setShowBatalkan(true);
                      }}
                    >
                      Batal
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-white flex p-4 font-inter justify-between items-center mt-3.5">
        <p className="text-sm text-black2">Data 1 hingga 25 dari 500</p>
        <div className="flex flex-col whitespace-nowrap justify-end md:block">
          <Button
            isGreenOutline
            isBtnOutlineSecondary
            title="Prev Page"
            className="mr-2 w-full md:w-max mb-2"
          />
          <Button isGreenOutline isBtnOutlineSecondary title="Next Page" />
        </div>
      </div>

      {showModal.show && (
        <Modal
          idPasien={idPasien}
          serviceLocationId={showModal.lokasiVaksin}
          type="update-status-antrian"
          closeModal={() => setShowModal(!showModal)}
          value={(value) => Antrian(value)}
          tes={() => _getAntrianKlinik()}
        />
      )}

      {showBatalkan && (
        <Modal
          idPasien={idPasien}
          type="batalkan-vaksinasi"
          closeModal={() => setShowBatalkan(!showBatalkan)}
          value={(value) => Antrian(value)}
          tes={() => _getAntrianKlinik()}
        />
      )}
      {noInternet && <Modal type="no-internet" />}
    </div>
  );
}
