import API from 'config/Axios';
import { getSearchFamily } from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Modal } from '..';
import Button from '../Button';
import Stepper, { Controller, MainContent, Meta } from '../Stepper';

export default function TambahDaftarKeluarga({ closeModal, data, onChange }) {
  const [showTambahDaftarKeluarga, setShowTambahDaftarKeluarga] =
    useState(false);
  const [resultFamily, setResultFamily] = useState('');
  const [keyword, setKeyword] = useState('');

  const dispatch = useDispatch();

  const _getFamily = async () => {
    await API.get(`/staff/families/${keyword}`)
      .then((res) => {
        setResultFamily(res.data.family);
      })
      .catch((err) => {
        return err;
      });
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-30">
      <div
        className="w-auto max-h-full bg-white flex flex-col justify-center py-16 px-8 relative z-30 "
        style={{ width: 420, minHeight: 358 }}
      >
        <div className="absolute top-5 right-7">
          <svg
            className="cursor-pointer"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={closeModal}
          >
            <path
              d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
              fill="#C8C8C8"
            />
          </svg>
        </div>

        <h2 className="font-inter font-semibold text-base mb-8">
          Masukkan ID Keluarga
        </h2>

        <div className="flex mb-8">
          <Input
            withOutline
            name="id-keluarga"
            label="ID Keluarga"
            className="mr-3"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />

          <Button
            isPrimary
            title="Cari ID"
            className="rounded-3"
            onClick={() => _getFamily()}
          />
        </div>

        {resultFamily !== '' && (
          <div className="p-4 border border-gray2 rounded-10 shadow">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => setShowTambahDaftarKeluarga(true)}
            >
              <div className="w-10 h-10 rounded-full bg-blue1 flex justify-center items-center">
                <svg
                  width="26"
                  height="19"
                  viewBox="0 0 26 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5538 9.47568C11.9607 9.47568 13.9186 7.51776 13.9186 5.11089C13.9186 2.70402 11.9607 0.746094 9.5538 0.746094C7.14693 0.746094 5.18901 2.70402 5.18901 5.11089C5.18901 7.51776 7.14693 9.47568 9.5538 9.47568ZM9.5538 3.24026C10.5889 3.24026 11.4244 4.07581 11.4244 5.11089C11.4244 6.14597 10.5889 6.98151 9.5538 6.98151C8.51872 6.98151 7.68318 6.14597 7.68318 5.11089C7.68318 4.07581 8.51872 3.24026 9.5538 3.24026ZM9.61616 15.7111H4.27864C5.51325 15.0876 7.64577 14.464 9.5538 14.464C9.69098 14.464 9.84063 14.4765 9.97781 14.4765C10.4018 13.5661 11.1376 12.8179 12.023 12.2193C11.1127 12.0571 10.2522 11.9698 9.5538 11.9698C6.63563 11.9698 0.824219 13.4289 0.824219 16.3346V18.2053H9.5538V16.3346C9.5538 16.1226 9.57874 15.9106 9.61616 15.7111ZM18.9069 12.5934C16.6123 12.5934 12.048 13.8529 12.048 16.3346V18.2053H25.7659V16.3346C25.7659 13.8529 21.2016 12.5934 18.9069 12.5934ZM20.4159 10.3237C21.3637 9.78745 22.0246 8.77731 22.0246 7.60505C22.0246 6.77819 21.6962 5.98518 21.1115 5.4005C20.5268 4.81582 19.7338 4.48734 18.9069 4.48734C18.0801 4.48734 17.2871 4.81582 16.7024 5.4005C16.1177 5.98518 15.7892 6.77819 15.7892 7.60505C15.7892 8.77731 16.4502 9.78745 17.398 10.3237C17.8469 10.5731 18.3582 10.7228 18.9069 10.7228C19.4556 10.7228 19.9669 10.5731 20.4159 10.3237Z"
                    fill="white"
                  />
                </svg>
              </div>

              <div className="ml-4">
                <p className="font-inter font-bold text-sm mb-1">
                  Keluarga #{resultFamily.id}
                </p>
                <p className="font-inter text-xs text-gray1 mb-1">
                  Pemilik Akun :{' '}
                  <span className="text-black2">
                    {resultFamily.registrant.user.name}
                  </span>
                </p>
                <p className="font-inter text-xs text-gray1">
                  Nomor Telpon :{' '}
                  <span className="text-black2">
                    {resultFamily.registrant.user.phone_number}
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      {showTambahDaftarKeluarga && (
        <Modal
          type="form-daftar-keluarga"
          closeModal={() => setShowTambahDaftarKeluarga(false)}
          data={resultFamily}
        />
      )}
    </div>
  );
}
