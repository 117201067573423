import { IconCloseRed } from "assets";
import React from "react";
import { Button } from "../../../components";

export default function ReadyTicket({ closeModal }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-20">
      <div
        className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative"
        style={{ width: 512, height: 450 }}
      >
        <div className="border-4 border-red1 rounded-full w-20 h-20 flex items-center justify-center">
          <img src={IconCloseRed} alt="" className="w-6 h-6" />
        </div>

        <p className="font-inter font-bold text-2xl my-8 text-center">
          Pasien ini sudah memiliki tiket !
        </p>

        <Button
          isBtnOutlineSecondary
          title="Kembali"
          className="rounded-3 text-gray1"
          onClick={() => closeModal()}
        />
      </div>
    </div>
  );
}
