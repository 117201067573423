import { formatDate } from 'utils';
import Button from '../Button/Button';
import LabelStatus from '../LabelStatus';

export const Header = ({
  data,
  title,
  subTitle,
  withMeasurement,
  status,
  onChangeStatus,
  createdAt,
  withBtnCancelTransction,
  onClickCancelTransction,
}) => {
  return (
    <>
      <div className="content border-b pb-6 border-gray2 font-inter flex justify-between items-center">
        <div>
          <h2 className=" font-bold text-3xl text-black mb-2">{title}</h2>
          <div className="lg:flex justify-between flex">
            <p className="text-sm font-normal">{subTitle || '-'}</p>
            {withMeasurement && (
              <div className="flex justify-between mt-2 lg:mt-0">
                <p className="text-sm font-normal mb-1 mr-4">
                  {`Tinggi Badan : ${data?.measurement?.body_height || '-'} cm`}
                </p>
                <p className="text-sm font-normal mb-1 mr-4">
                  {`Suhu Badan : ${data?.measurement?.body_temperature || '-'}`}
                  &nbsp;
                  <sup>o</sup>C
                </p>

                <p className="text-sm font-normal mb-1 mr-4">{`Berat Badan : ${
                  data?.measurement?.body_weight || '-'
                } kg`}</p>
                <p className="text-sm font-normal">{`Lingkar Kepala : ${
                  data?.measurement?.head_circumference || '-'
                } cm`}</p>
              </div>
            )}
          </div>
        </div>
        {withBtnCancelTransction && (
          <div className="">
            <Button
              isDanger
              isBordered
              isDisabled={
                data?.order_status_id === 3 || data?.order_status_id === 2
              }
              onClick={onClickCancelTransction}
            >
              Batalkan Transaksi
            </Button>
          </div>
        )}
      </div>

      <div className="md:block lg:flex mt-4 min-w-full items-center">
        <div className="w-full lg:w-6/12 ">
          <div className="flex items-center">
            <LabelStatus
              withOutline
              type={status}
              title={status}
              className="mr-5"
            />

            <button
              className="font-inter font-bold text-xs text-black2 hover:text-blue3 focus:outline-none focus:text-blue1"
              onClick={onChangeStatus}
            >
              {withMeasurement
                ? 'Ubah Status Antrian'
                : 'Ubah Status Pembayaran'}
            </button>
          </div>
        </div>
        <div className="w-full lg:w-1/12" />

        <div className="w-full lg:w-6/12 mt-2 lg:mt-0">
          <p>Dibuat Pada {formatDate(createdAt)}</p>
        </div>
      </div>
    </>
  );
};
