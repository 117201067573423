import { IconLoading } from 'assets';
import React from 'react';

export const LoadingFullContent = () => {
  return (
    <div className="h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll flex justify-center items-center">
      <div className="">
        <div className="flex justify-center mb-16 animate-spin">
          <img
            src={IconLoading}
            alt="icon loading"
            className=" justify-center"
          />
        </div>
        <h3 className="font-inter font-bold text-2xl text-center">
          Silahkan Tunggu
        </h3>
      </div>
    </div>
  );
};
