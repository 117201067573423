import { IconDone } from "assets";
import { setBtnPetaVaksinasi, updateStatusAntrian } from "config/Redux/action";
import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "..";

export default function PenyelesaianVaksinasi({
  closeModal,
  value,
  idPasien,
  tes,
}) {
  const dispatch = useDispatch();

  const handleClickList = async () => {
    await dispatch(updateStatusAntrian(`/staff/queues/${idPasien}`, 7));
    await dispatch(setBtnPetaVaksinasi(-1, false));

    return closeModal();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center">
      <div
        className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative"
        style={{ width: 512, height: 450 }}
      >
        <div className=" rounded-full w-20 h-20 flex items-center justify-center">
          <img src={IconDone} alt="" className="w-20 h-20 " />
        </div>

        <p className="font-inter font-bold text-2xl my-8 text-center">
          Apakah Semua Proses Telah Selesai ?
        </p>

        <div className="flex">
          <Button
            isBtnOutlineSecondary
            title="Kembali"
            className="rounded-3 mr-9 text-gray1"
            onClick={() => closeModal()}
          />
          <Button
            isPrimary
            title="Ya, Selesai"
            className="rounded-3 bg-blue1 border border-blue1 hover:border-blue1 hover:bg-blue1"
            onClick={handleClickList}
          />
        </div>
      </div>
    </div>
  );
}
