import { IconNoInternet } from "assets";
import React from "react";
import Button from "../Button";

export default function NoInternet() {
  return (
    <div className="fixed inset-0 flex justify-center items-center ">
      <div className="tes-blur absolute inset-0 bg-black bg-opacity-60"></div>
      <div
        className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative rounded-2xl "
        style={{ width: 721, height: 507 }}
      >
        <img src={IconNoInternet} alt="no-internet-illustration" />
        <h1 className="font-inter font-bold  text-xl text-red1 mt-8 text-center">
          Anda tidak terhubung !
        </h1>
        <p className="font-inter text-sm mt-1 text-center">
          Periksa sambungan internet atau hubungi tim IT
        </p>

        <Button
          title="Refresh"
          isPrimary
          className="rounded-3 mt-9"
          onClick={() => window.location.reload(false)}
        />
      </div>
    </div>
  );
}
