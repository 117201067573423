import React from "react";

export default function InputIcon({
  type,
  name,
  placeholder,
  onChange,
  className,
  autoComplete,
  value,
  icon,
}) {
  return (
    <div
      className={`flex justify-between border border-gray1 rounded-3 bg-white ${className}`}
    >
      <input
        name={name}
        type={type}
        className={`border-none focus:outline-none focus:ring-0 px-0 w-full font-inter font-light text-sm`}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      <img src={icon} alt="asdasd" />
    </div>
  );
}
