import { Button } from 'components/atoms';
import React from 'react';

export const HeaderHomecare = ({
  data,
  isLoading,
  onUpdateVaccinationLocation,
}) => {
  return (
    <div className="flex justify-between items-center mb-8">
      <h1 className="font-inter font-bold text-2xl text-black2">
        Peta Vaksinasi Hari ini
      </h1>

      <Button
        isDisabled={isLoading}
        isPrimaryOutline
        isBtnOutlineSecondary
        title={isLoading ? 'Loading...' : 'Update'}
        className="rounded-3"
        onClick={onUpdateVaccinationLocation}
      />
    </div>
  );
};
