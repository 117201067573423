import { IconCloseRed } from 'assets';
import API from 'config/Axios';
import { updateStatusAntrian } from 'config/Redux/action';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '..';

export default function HapusProdukOrDokter({
  closeModal,
  value,
  id,
  tes,
  isDeleteProduct,
  isDeleteDoctor,
  isDeleteFamily,
  data,
}) {
  const handleClickList = async () => {
    if (isDeleteProduct) {
      API.delete(`/staff/vaccines/${data.id}`).then((res) => {
        closeModal();
        return tes();
      });
    } else if (isDeleteDoctor) {
      API.delete(`/staff/doctors/${data.id}`).then((res) => {
        closeModal();
        return tes();
      });
    } else {
      API.delete(`/staff/families/${data.id}`).then((res) => {
        closeModal();
        return tes();
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center">
      <div
        className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative"
        style={{ width: 512, height: 450 }}
      >
        <div className="border-4 border-red1 rounded-full w-20 h-20 flex items-center justify-center">
          <img src={IconCloseRed} alt="" className="w-6 h-6" />
        </div>

        {isDeleteProduct && (
          <p className="font-inter font-bold text-2xl my-8 capitalize text-center">
            Hapus Vaksin <span className="text-red1">{data.brand_name}</span>{' '}
            dari daftar produk ?
          </p>
        )}

        {isDeleteDoctor && (
          <p className="font-inter font-bold text-2xl my-8 capitalize text-center">
            Hapus dokter <span className="text-red1">{data?.fullname}</span>{' '}
            dari daftar dokter ?
          </p>
        )}

        {isDeleteFamily && (
          <p className="font-inter font-bold text-2xl my-8 capitalize text-center">
            Hapus <span className="text-red1">{data?.family_name}</span>
          </p>
        )}

        <div className="flex">
          <Button
            isBtnOutlineSecondary
            title="Kembali"
            className="rounded-3 mr-9 text-gray1"
            onClick={() => closeModal()}
          />
          <Button
            isPrimary
            title="Hapus"
            className="rounded-3 bg-red1 border border-red1 hover:border-red1 hover:bg-red1"
            onClick={handleClickList}
          />
        </div>
      </div>
    </div>
  );
}
