import { IconSearch, IconLoading, IconAntrianKosong } from 'assets';
import { Input } from 'components/atoms';
import React, { useEffect, useState } from 'react';
import ButtonNew from 'components/atoms/Button/Button';
import moment from 'moment';
import ModalCreateEditMessage from './Components/ModalCreateEditMessage';
import useDisclosure from 'Hooks/useDisclosure/useDisclosure';
import API from 'config/Axios';
import { toast } from 'react-toastify';
import useGetListMessages from './Hooks/useGetListMessages';
import ModalConfirm from 'componentsV2/Modal/ConfirmModal';
import { ModalSendBroadcast } from './Components/ModalSendBroadcast';

const Broadcast = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSendMessage, setIsLoadingSendMessage] = useState(false);
  const [messageId, setMessageId] = useState(null);
  const [dataMessageEdit, setDataMessageEdit] = useState(null);

  const modalCreateEditMessage = useDisclosure();
  const modalConfirmDeleteMessage = useDisclosure();
  const modalSendMessage = useDisclosure();

  const {
    data: listMessages,
    isLoading: isLoadingListMessages,
    refetch,
  } = useGetListMessages();

  const onCloseModalConfirmDelete = () => {
    setMessageId(null);
    modalConfirmDeleteMessage.onClose();
  };

  const onCloseModalSendMessage = () => {
    modalSendMessage.onClose();
  };

  const onShowModalDeleteMessage = (messageId) => {
    setMessageId(messageId);
    modalConfirmDeleteMessage.onOpen();
  };

  const onShowModalEditMessage = (message) => {
    setDataMessageEdit(message);
    modalCreateEditMessage.onOpen();
  };

  const onShowModalSendMessage = (id) => {
    setMessageId(id);
    modalSendMessage.onOpen();
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    const messageId = dataMessageEdit?.id || null;

    const payload = {
      ...(messageId && { _method: 'PATCH' }),
      title: data?.title,
      body: data?.text,
    };

    const endpointCreate = '/staff/broadcast-messages';
    const endpointEdit = `/staff/broadcast-messages/${messageId}`;

    const url = messageId ? endpointEdit : endpointCreate;

    API.post(url, payload)
      .then(() => {
        const textSuccess = messageId
          ? 'Berhasil edit pesan !'
          : 'Berhasil buat pesan !';

        toast.success(textSuccess);
        modalCreateEditMessage.onClose();
        refetch();
      })
      .catch(() => {
        const textError = messageId
          ? 'Gagal edit pesan !'
          : 'Gagal buat pesan !';

        toast.error(textError);
      })
      .finally(() => {
        setDataMessageEdit(null);
        setIsLoading(false);
      });
  };

  const onDeleteMessage = () => {
    setIsLoading(true);
    API.delete(`/staff/broadcast-messages/${messageId}`)
      .then(() => {
        toast.success('Berhasil menghapus pesan !');
        setMessageId(null);
        modalConfirmDeleteMessage.onClose();
        refetch();
      })
      .catch(() => toast.error('Gagal menghapus pesan !'))
      .finally(() => {
        setMessageId(null);
        setIsLoading(false);
      });
  };

  const onSendMessage = () => {
    setIsLoadingSendMessage(true);
    API.get(`/staff/broadcast-messages/send/${messageId}`)
      .then((res) => {
        toast.success('Berhasil kirim notifikasi !');
        setMessageId(null);
        modalSendMessage.onClose();
      })
      .catch((err) => toast.error('Gagal kirim notifikasi !'))
      .finally(() => {
        setIsLoading(false);
        setMessageId(null);
      });
  };

  return (
    <div className="flex flex-col h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll">
      <div className="flex items-center justify-between mb-6 ">
        <div className="font-inter">
          <h2 className="font-bold text-2xl text-black2">Broadcast</h2>
        </div>

        <div className="block lg:flex ">
          <ButtonNew isPrimary onClick={modalCreateEditMessage.onOpen}>
            Buat Pesan
          </ButtonNew>
          {/* <Input
            withIcon
            type="text"
            name="search"
            className="px-2 py-1"
            placeholder="Cari data..."
            icon={IconSearch}
          /> */}
        </div>
      </div>
      <div className="flex-1">
        <div className="h-full min-w-full overflow-x-scroll">
          <table className="w-full">
            <thead>
              <tr className="bg-blue4 text-black2">
                <th className="w-2/12 py-3 px-8 border border-blue4">
                  Judul Pesan
                </th>
                <th className="w-5/12 py-3 px-8 border border-blue4">
                  Isi Pesan
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Terakhir Dikirim
                </th>
                <th className="border border-blue4 py-3 px-8">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {isLoadingListMessages ? (
                <tr className="text-center animate-AfterLoading">
                  <td colSpan={6} className="whitespace-nowrap p-4">
                    <img
                      src={IconLoading}
                      alt=""
                      className="mx-auto mb-7 animate-spin w-16 h-16 mt-4"
                    />
                    <p className="animate-pulse">Loading...</p>
                  </td>
                </tr>
              ) : (
                <>
                  {listMessages?.data.length === 0 && !isLoadingListMessages ? (
                    <tr className="text-center">
                      <td colSpan={6} className="whitespace-nowrap p-4">
                        <img
                          src={IconAntrianKosong}
                          alt=""
                          className="mx-auto mb-7"
                        />
                        <p className="ml-6">Pesan masih kosong</p>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {listMessages?.data?.map((message) => (
                        <tr className="text-center" key={message.id}>
                          <td className="border border-blue4 p-2 whitespace-nowrap">
                            {message?.title || '-'}
                          </td>
                          <td className="border border-blue4 p-2">
                            {message?.body || '-'}
                          </td>
                          <td className="border border-blue4 p-2">
                            {message?.last_sent
                              ? moment(message?.last_sent).format(
                                  'DD MMMM YYYY, hh:mm A'
                                )
                              : '-'}
                          </td>
                          <td className="border border-blue4 p-2">
                            <div className="flex gap-2 items-center justify-center">
                              <ButtonNew
                                isSuccess
                                onClick={() =>
                                  onShowModalSendMessage(message?.id)
                                }
                              >
                                Kirim
                              </ButtonNew>
                              <ButtonNew
                                isPrimary
                                onClick={() => onShowModalEditMessage(message)}
                              >
                                Ubah
                              </ButtonNew>
                              <ButtonNew
                                isDanger
                                onClick={() =>
                                  onShowModalDeleteMessage(message?.id)
                                }
                              >
                                Hapus
                              </ButtonNew>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <ModalCreateEditMessage
        isOpen={modalCreateEditMessage.isOpen}
        onClose={modalCreateEditMessage.onClose}
        isLoading={isLoading}
        messageTitle={dataMessageEdit?.title || ''}
        messageText={dataMessageEdit?.body || ''}
        onSubmit={onSubmit}
      />

      <ModalConfirm
        isOpen={modalConfirmDeleteMessage.isOpen}
        onClose={onCloseModalConfirmDelete}
        description="Apakah anda yakin untuk menghapus pesan ini ?"
        okText="Hapus"
        cancelText="Batal"
        isLoading={isLoading}
        onOk={onDeleteMessage}
      />

      <ModalSendBroadcast
        isOpen={modalSendMessage.isOpen}
        title=""
        isLoading={isLoadingSendMessage}
        onClose={onCloseModalSendMessage}
        cancelText="Batal"
        okText="Kirim"
        onOk={onSendMessage}
      />
    </div>
  );
};

export default Broadcast;
