import { TextContent } from 'components/atoms/Text/TextContent';
import React from 'react';

export const AdditionalPaymentEmpty = () => {
  return (
    <>
      <div className="flex justify-between">
        <TextContent text="Tagihan Tambahan" />
        <TextContent text="Rp. 0" />
      </div>
      <div className="flex justify-between mt-3">
        <TextContent text="Refund" />
        <TextContent text="Rp. 0" />
      </div>
    </>
  );
};
