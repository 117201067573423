import { IconNoPhoto } from 'assets';
import React from 'react';

export const PatientVaccineDetail = ({ data, onClick }) => {
  return (
    <>
      <h2 className="font-bold text-lg text-black mb-4">Penerima Vaksin</h2>

      <div className="flex items-center border-b border-gray2 pb-7">
        <img src={IconNoPhoto} alt="default-avatar" />
        <p className="font-inter font-semibold text-sm text-black2 ml-5 mr-12">
          {data.family_member?.fullname || '-'}
        </p>
        <p
          className="font-inter font-semibold text-sm text-blue1 cursor-pointer"
          onClick={onClick}
        >
          Lihat Profil
        </p>
      </div>
    </>
  );
};
