import { IconAntrianKosong, IconLoading, IconSearch } from 'assets';
import { Button, Input, LabelStatus, Modal } from 'components/atoms';
import {
  getAllOrders,
  getMerekVaksin,
  getStatusAntrian,
  getStatusPembayaran,
} from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { formatDate, formatNumber, useForm } from 'utils';
import { createPagination } from 'utils/createPagination/createPagination';
import ButtonNew from 'components/atoms/Button/Button';
import _ from 'lodash';

const CURRENT_PAGE = 1;
const PAGE_SIZE = 5;

export default function DaftarProduk(props) {
  const [listSearch, setListSearch] = useState('');
  const [search, setSearch] = useState('');
  const [keySearchDebounce] = useDebounce(search, 300);
  const [idPasien, setIdPasien] = useState('');
  const [showBatalkan, setShowBatalkan] = useState({ show: false, data: '' });
  const [listAntrian, setListAntrian] = useState([]);
  const [statusPembayaran, setStatusPembayaran] = useState([]);
  const [statusPembayaranId, setStatusPembayaranId] = useState('');
  const [tglVaksin, setTglVaksin] = useState('');
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [resultPagination, setResultPagination] = useState([]);

  const [listProduk, setListProduk] = useState([]);
  const [tipeVaksin, setTipeVaksin] = useState('');
  const [modalDetailProduk, setModalDetailProduk] = useState({
    show: false,
    id: '',
  });
  const [modalTambahProduk, setModalTambahProduk] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const numberOfData = PAGE_SIZE * page;

  const dispatch = useDispatch();

  const _getListVaksin = async () => {
    setIsLoading(true);
    setListProduk([]);
    const merekVaksin = await dispatch(
      getMerekVaksin('/staff/vaccines', keySearchDebounce)
    );
    setListProduk(merekVaksin.vaccines);
    setTotalData(merekVaksin.vaccines.length);
    setIsLoading(false);
  };

  useEffect(() => {
    _getListVaksin();
  }, [keySearchDebounce, tglVaksin]);

  useEffect(() => {
    const paginationData = createPagination(listProduk, page, PAGE_SIZE);
    const tempLastPage = Math.ceil(listProduk.length / PAGE_SIZE);
    setTotalPage(tempLastPage);
    setResultPagination(paginationData);
  }, [page, listProduk]);

  return (
    <div className="flex flex-col h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll">
      {/* START : Header Content */}
      <div className="content flex justify-between border-b-2 pb-10 mb-8 border-gray2">
        <div className="font-inter">
          <h2 className="font-bold text-3xl text-black2">Produk Vaksinasi </h2>
        </div>
      </div>
      {/* END : Header Content */}

      <div className="flex items-center justify-between mb-6 ">
        <div className="font-inter">
          <h2 className="font-bold text-2xl text-black2">Daftar Produk</h2>
        </div>

        <div className="block lg:flex lg:items-center ">
          <div className="mb-4 lg:mb-0 flex justify- items-center">
            <p className="text-sm font-semibold font-inter mr-5 whitespace-nowrap">
              Filter :
            </p>
          </div>

          <Input
            withIcon
            type="text"
            name="search"
            value={search}
            className="px-2 py-1"
            // onChange={(e) => setSearch(e.target.value)}
            placeholder="Cari data..."
            icon={IconSearch}
            onChange={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
          />

          <Button
            title="+ Tambah Produk"
            className="bg-blue1 px-7 py-3 font-inter font-bold text-sm text-white rounded-3 mt-4 lg:mt-0  w-full lg:w-max lg:ml-7 hover:bg-blue3 md:mb-3 lg:mb-0 whitespace-nowrap"
            onClick={() => setModalTambahProduk(true)}
          />
        </div>
      </div>

      <div className="flex-1">
        <div className="h-full min-w-full overflow-x-scroll">
          <table className="w-full">
            <thead>
              <tr className="bg-blue4 text-black2">
                <th className="w-2/12 py-3 px-8 border border-blue4">No</th>
                <th className="w-1/12 py-3 px-8 border border-blue4">
                  Merek Vaksin
                </th>
                <th className="w-1/12 py-3 px-2 border border-blue4">
                  Jenis Vaksin
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Stok
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Harga
                </th>
                <th className="w-4/12  border border-blue4 whitespace-nowrap py-3">
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr className="text-center animate-AfterLoading">
                  <td colSpan={7} className="whitespace-nowrap p-4">
                    <img
                      src={IconLoading}
                      alt=""
                      className="mx-auto mb-7 animate-spin mt-8"
                    />
                    <p className="animate-pulse">Loading...</p>
                  </td>
                </tr>
              )}
              {resultPagination.length === 0 && !isLoading ? (
                <tr className="text-center">
                  <td colSpan={7} className="whitespace-nowrap p-4">
                    <img
                      src={IconAntrianKosong}
                      alt=""
                      className="mx-auto mb-7"
                    />
                    <p className="ml-6">Belum ada produk vaksin</p>
                  </td>
                </tr>
              ) : (
                <>
                  {resultPagination?.map((list) => (
                    <tr className="text-center" key={list.id}>
                      <td className="border border-blue4 px-4 whitespace-nowrap">
                        {list.id}
                      </td>
                      <td className="border border-blue4 px-4 whitespace-nowrap">
                        {list.brand_name}
                      </td>
                      <td className="border border-blue4 px-4 whitespace-nowrap">
                        {list.vaccine_types
                          ?.map((tipe, i) => tipe.name)
                          ?.join(', ') || '-'}
                      </td>
                      <td className="border border-blue4 px-4 whitespace-nowrap">
                        {list.stock}
                      </td>
                      <td className="border border-blue4 px-4 whitespace-nowrap">
                        {formatNumber(list.price)}
                      </td>

                      <td className="border border-blue4 text-left px-2 py-3 flex justify-center">
                        <Button
                          title="Edit"
                          className="bg-blue1 text-white border rounded-3 hover:bg-blue3 font-inter font-medium text-xs focus:bg-blue5 focus:outline-none px-4 py-2 mb-1 mr-2"
                          onClick={() =>
                            setModalDetailProduk({ show: true, id: list.id })
                          }
                        />

                        <button
                          className="bg-red1 text-white border rounded-3 hover:bg-red-400 font-inter font-medium text-xs px-4 py-2 mb-1"
                          onClick={() => {
                            setIdPasien(list.id);
                            setShowBatalkan({ show: true, data: list });
                          }}
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-white flex p-4 font-inter justify-between items-center mt-3.5">
        <p className="text-sm text-black2">{`Data ${
          numberOfData - (PAGE_SIZE - 1)
        } hingga ${numberOfData > totalData ? totalData : numberOfData} dari ${
          totalData || 0
        }`}</p>
        <div className="flex flex-col whitespace-nowrap justify-end md:block">
          <ButtonNew
            isSuccess
            isBordered
            isDisabled={page === 1}
            onClick={() => setPage(page - 1)}
            className="mr-2"
          >
            Prev Page
          </ButtonNew>

          <ButtonNew
            isSuccess
            isBordered
            isDisabled={page === totalPage}
            onClick={() => setPage(page + 1)}
          >
            Next Page
          </ButtonNew>
        </div>
      </div>

      {showBatalkan.show && (
        <Modal
          data={showBatalkan.data}
          type="hapus-produk-dokter"
          isDeleteProduct
          closeModal={() => setShowBatalkan(!showBatalkan)}
          // value={(value) => Antrian(value)}
          tes={async () => await _getListVaksin()}
        />
      )}

      {modalDetailProduk.show && (
        <Modal
          type="detail-produk"
          idPasien={modalDetailProduk.id}
          closeModal={() => setModalDetailProduk(false)}
        />
      )}

      {modalTambahProduk && (
        <Modal
          type="tambah-produk"
          // idPasien={modalDetailProduk.id}
          closeModal={() => setModalTambahProduk(false)}
        />
      )}
    </div>
  );
}
