// const LocalStorageService = (function () {
//   var _service;
//   function _getService() {
//     if (!_service) {
//       _service = this;
//       return _service;
//     }
//     return _service;
//   }
//   function _setToken(tokenObj) {
//     localStorage.setItem("access_token", tokenObj.access_token);
//   }
//   function _getAccessToken() {
//     return localStorage.getItem("access_token");
//   }

//   function _clearToken() {
//     localStorage.removeItem("access_token");
//   }
//   return {
//     getService: _getService,
//     setToken: _setToken,
//     getAccessToken: _getAccessToken,
//     clearToken: _clearToken,
//   };
// })();
// export default LocalStorageService;

const getAccessToken = () => {
  return localStorage.getItem('token');
};

const setAccessToken = (value) => {
  return localStorage.setItem('token', value);
};

const getLocalRuangBertugas = () => {
  return localStorage.getItem('ruang bertugas');
};

const getLocalRole = () => {
  return localStorage.getItem('role');
};

const setLocalRuangBertugas = (value) => {
  return localStorage.setItem('ruang bertugas', value);
};

const getPaymentMethod = () => {
  return JSON.parse(localStorage.getItem('payment_method'));
};

const setPaymentMethod = (value) => {
  return localStorage.setItem('payment_method', value);
};

const getPaymentChannel = () => {
  return JSON.parse(localStorage.getItem('payment_channel'));
};

const setPaymentChannel = (value) => {
  return localStorage.setItem('payment_channel', value);
};

export {
  getAccessToken,
  setAccessToken,
  getLocalRuangBertugas,
  setLocalRuangBertugas,
  getLocalRole,
  getPaymentMethod,
  setPaymentMethod,
  getPaymentChannel,
  setPaymentChannel,
};
