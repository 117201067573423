import React from 'react';

export const TextContent = ({ text, className, isSecondary = false }) => {
  return (
    <p
      className={`font-inter font-normal text-sm ${
        !isSecondary ? 'text-black2' : 'text-gray-500'
      }  ${className}`}
    >
      {text || '-'}
    </p>
  );
};
