import { IconHidePassword } from "assets";
import React, { useState } from "react";

export const InputValidation = React.forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const [focus, setFocus] = useState(false);

  const {
    type,
    name,
    placeholder,
    onChange,
    className,
    autoComplete,
    icon,
  } = props;

  if (icon) {
    return (
      <div
        className={`flex mb-7 border text-black1  bg-white pr-3 rounded-xl justify-between ${
          focus ? "ring ring-blue-300 border-blue-200" : "border-gray1 p-0"
        } ${className}`}
      >
        <input
          type={showPassword ? "text" : type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          className={`border-none focus:ring-0 text-current flex-1 rounded-xl input`}
          autoComplete={autoComplete}
          ref={ref}
        />

        <img
          src={showPassword ? IconHidePassword : icon}
          alt={name}
          onClick={() => setShowPassword(!showPassword)}
          className="cursor-pointer"
        />
      </div>
    );
  }

  return (
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      className={`mb-7 input focus:outline-none ${
        focus ? "focus:ring focus:ring-blue-300 focus:border-blue-200" : ""
      }  ${className}`}
      autoComplete={autoComplete}
      ref={ref}
    />
  );
});
