import React from "react";

export default function Antrian() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 5.5V19.2222C20 20.2041 19.2041 21 18.2222 21H5.77778C4.79594 21 4 20.2041 4 19.2222V5.5"
        className="stroke-current"
        stroke="#313131"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 10H8V16H11V10Z"
        className="stroke-current"
        stroke="#313131"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9H15.3619L13 10.3684V11.7911L15.2227 10.5106H15.2784V17H17V9Z"
        fill="#313131"
        className="fill-current"
      />
      <line
        x1="2"
        y1="5.25"
        x2="22"
        y2="5.25"
        className="stroke-current"
        stroke="#313131"
        strokeWidth="1.5"
      />
    </svg>
  );
}
