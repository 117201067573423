import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

export class Autocomplete extends Component {
  static propTypes = {
    options: PropTypes.instanceOf(Array).isRequired,
  };

  state = {
    listName: [],
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: '',
    isActive: false,
  };

  onResetAutoComplete = () => {
    this.setState({
      listName: [],
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: '',
      isActive: false,
    });
  };

  onChange = (e) => {
    const { options } = this.props;
    const userInput = e.currentTarget.value;
    this.props.userInput(e.currentTarget.value);

    if (userInput.length > 2) {
      const filteredOptions = options.filter(
        (optionName) =>
          optionName.fullname.toLowerCase().indexOf(userInput.toLowerCase()) >
          -1
      );

      return this.setState({
        activeOption: 0,
        filteredOptions,
        showOptions: true,
        userInput: e.currentTarget.value,
      });
    }

    this.setState({
      activeOption: 0,
      filteredOptions: '',
      showOptions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e, userClick) => {
    this.setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: `${userClick.fullname} (${userClick.age.y} thn)`,
      // userInput: e.currentTarget.innerText,
    });

    // return this.props.available(true);
    return this.props.setvalue(userClick);
  };

  onKeyDown = (e) => {
    const { activeOption, filteredOptions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: `${filteredOptions[activeOption].fullname} (${filteredOptions[activeOption].age.y} thn)`,
      });

      return this.props.setvalue(
        `${filteredOptions[activeOption].fullname} ${filteredOptions[activeOption].age.y}`
      );
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        return;
      }
      this.setState({ activeOption: activeOption + 1 });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.isReset !== prevProps.isReset) {
      this.onResetAutoComplete();
    }
  }

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,

      state: { activeOption, filteredOptions, showOptions, userInput },
    } = this;
    let optionList;

    const onClickAddNewPatient = () => {
      window.open('/dashboard/daftar-keluarga', '_blank');
    };

    if (showOptions && userInput.length > 2) {
      if (this.props.isloading) {
        optionList = (
          <div className="no-options">
            <ul className="options font-inter cursor-pointer">
              <li className="flex items-center justify-between border border-gray2 rounded-3 px-4 py-3 hover:bg-blue2 focus-within:bg-blue1">
                <span className="text-sm text-black2">Loading...</span>
              </li>
            </ul>
          </div>
        );
      } else if (this.props.options.length) {
        optionList = (
          <div
            className={`bg-white absolute inset-x-0 top-14 z-30 ${
              this.state.userInput === '' ? 'hidden' : ''
            }`}
          >
            <ul className="options font-inter cursor-pointer">
              {this.props.options.map((optionName, index) => {
                let className;
                if (index === activeOption) {
                  className = 'option-active';
                }
                return (
                  <li
                    className={`flex items-center justify-between border border-gray2 rounded-3 px-4 py-3 hover:bg-blue2 focus-within:bg-blue1 ${className}`}
                    key={index}
                    onClick={(e) => onClick(e, optionName)}
                  >
                    <span className="max-w-1/2 hover:max-w-full flex items-center">
                      <span className="w-full hover:w-max text-sm text-current truncate hover:overflow-clip">
                        {`${optionName.fullname}`} &#8287;
                      </span>
                      <span className="text-sm text-current whitespace-nowrap">
                        {`(${optionName.age.y} tahun)`}
                      </span>
                    </span>
                    <span className="w-auto text-xs text-blue1">Profil</span>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      } else {
        optionList = (
          <div className="no-options">
            <ul className="options font-inter ">
              <li className="flex items-center justify-between border border-gray2 rounded-3 px-4 py-3  ">
                <span className="text-sm text-black2">
                  Nama tidak ditemukan ?
                </span>
                <span
                  className="text-xs text-blue1 cursor-pointer hover:text-blue2"
                  onClick={onClickAddNewPatient}
                >
                  + Tambahkan Nama Baru
                </span>
              </li>
            </ul>
          </div>
        );
      }
    }

    return (
      <div className={`relative ${this.props.className}`}>
        <div
          className={`outline relative border border-black focus-within:border-blue1 focus-within:text-blue1 rounded-3 search ${this.props.className}`}
        >
          <input
            name={this.props.name}
            type="text"
            className={`block p-3 w-full text-sm font-inter appearance-none border-none focus:outline-none focus:ring rounded-3 text-black2 search-box ${
              this.props.name
            } ${
              this.state.isActive
                ? ''
                : 'relative z-10 bg-transparent placeholder-transparent'
            }`}
            onFocus={() => this.setState({ isActive: true })}
            onBlur={() => this.setState({ isActive: false })}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
            autoComplete="off"
            placeholder={this.props.placeholder ? this.props.placeholder : ' '}
            id={this.props.id}
            ref={this.props.innerRef}
          />
          <label
            htmlFor="username"
            className={`absolute top-0 text-sm font-inter bg-white p-3 duration-300 origin-0 rounded-full ${
              this.state.isActive
                ? 'z-10 focus-within:text-blue-600'
                : '-z-1 text-gray1'
            }`}
          >
            {this.props.label}
          </label>
          {/* <input type="submit" value="" className="search-btn" /> */}
        </div>
        {optionList}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Autocomplete innerRef={ref} {...props} />
));
