import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import ICClose from 'assets/icon/ic-close.svg';
import { Input } from 'components/atoms';

const ModalCreateEditMessage = ({
  isOpen,
  onClose,
  isLoading = false,
  messageTitle = '',
  messageText = '',
  onSubmit,
}) => {
  const [message, setMessage] = useState({
    title: '',
    text: '',
  });

  const onChangeMessage = (event) => {
    const { name, value } = event?.target;

    return setMessage((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (messageText || messageTitle) {
      setMessage({
        title: messageTitle,
        text: messageText,
      });
    }
  }, [messageText, messageTitle]);

  return (
    <div
      className={cx(
        'fixed inset-0 w-screen h-screen z-50 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 flex justify-center items-center',
        {
          hidden: !isOpen,
        }
      )}
    >
      <div className="relative w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {messageText || messageTitle ? 'Edit' : 'Buat'} Pesan
            </h3>
            <button
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={onClose}
            >
              <img src={ICClose} alt="ic-close.svg" className="h-4 w-4" />
            </button>
          </div>

          {/* content */}
          <div className="p-4 flex flex-col gap-5">
            <div className="flex">
              <div className="w-3/12">
                <p>Judul Pesan</p>
              </div>
              <div className="w-9/12">
                <Input
                  withOutline
                  name="title"
                  className="w-full"
                  value={message?.title}
                  onChange={onChangeMessage}
                />
              </div>
            </div>

            <div className="flex">
              <div className="w-3/12">
                <p>Isi Pesan</p>
              </div>
              <div className="w-9/12">
                <textarea
                  className="w-full rounded resize-none"
                  rows={6}
                  name="text"
                  value={message?.text}
                  onChange={onChangeMessage}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              onClick={onClose}
            >
              Batal
            </button>
            <button
              className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              disabled={isLoading}
              onClick={() => onSubmit(message)}
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCreateEditMessage;
