import { IconEditUnderline, IconLoading, IconRemove } from 'assets';
import { Button, Modal } from 'components/atoms';
import API from 'config/Axios';
import {
  getFamilyMember,
  getRiwayatVaksinasi,
  checkTicket,
} from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { formatDateAsc } from 'utils';

export default function Profile(props) {
  const [modalEditProfile, setModalEditProfile] = useState(false);
  const [familyMember, setFamilyMember] = useState('');
  const [anggotaKeluarga, setAnggotaKeluarga] = useState('');
  const [riwayat, setRiwayat] = useState('');
  const [initialName, setInitialName] = useState('');
  const [ticketAvailable, setTicketAvailable] = useState(false);
  const [showModalRiwayat, setShowModalRiwayat] = useState(false);
  const [showModalTambahAnggota, setShowModalTambahAnggota] = useState(false);
  const [resultFamily, setResultFamily] = useState('');
  const [familyID, setFamilyID] = useState(null);
  const [isShowModalCancelOrder, setIsShowModalCancelOrder] = useState(false);
  const [upcomingQueueId, setUpcomingQueueId] = useState(null);

  const dispatch = useDispatch();
  const { id } = useParams();

  const _getFamilyMember = async () => {
    const member = await dispatch(
      getFamilyMember(`/staff/family-members/${id}`)
    );
    setFamilyMember(member);

    const fullname = member.fullname.split(' ');
    setInitialName(`${fullname[0].charAt(0)}${fullname[1]?.charAt(0) || ''}`);

    const params = {
      family_id: member.family_id,
    };
    const anggota = await dispatch(
      getFamilyMember(`/staff/family-members`, params)
    );
    setAnggotaKeluarga(anggota);

    const riwayatVaksinasi = await dispatch(
      getRiwayatVaksinasi('/staff/vaccinations/history?', id)
    );
    setRiwayat(riwayatVaksinasi.vaccinations);
  };

  const _getFamily = async () => {
    await API.get(`/staff/families/${familyMember?.family_id}`)
      .then((res) => {
        setResultFamily(res.data.family);
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    const tempFamilyId = localStorage.getItem('family_id');
    const familyId = id || tempFamilyId;
    setFamilyID(parseInt(familyId));
  }, []);

  useEffect(() => {
    if (familyID && familyMember) {
      _getFamily();
    }
  }, [familyID, familyMember]);

  useEffect(() => {
    setFamilyMember('');
    setRiwayat('');
    setInitialName('');
    setAnggotaKeluarga('');
    _getFamilyMember();
    setModalEditProfile(false);
    setTicketAvailable(false);
    setShowModalRiwayat(false);
    setShowModalTambahAnggota(false);
  }, [id]);

  if (familyMember === '' || anggotaKeluarga === '' || !familyID) {
    return (
      <div className="h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll flex justify-center items-center">
        <div className="">
          <div className="flex justify-center mb-16 animate-spin">
            <img
              src={IconLoading}
              alt="icon loading"
              className=" justify-center"
            />
          </div>
          <h3 className="font-inter font-bold text-2xl text-center">
            Silahkan Tunggu
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="flex flex-1 overflow-hidden">
        <div className="bg-white pt-8 pb-8 px-16 overflow-y-scroll scrollbar-none border-b-8 border-blue2 w-full">
          <div className="content flex justify-between border-b-2 pb-5 mb-8 border-gray2 font-inter">
            <h2 className="font-bold text-3xl text-black">Profil Pasien</h2>
          </div>

          <div className="w-full block lg:flex">
            <div className="w-full lg:w-3/5 lg:border-r-2 lg:border-blue2 lg:pr-14">
              <div className="flex justify-between pb-10 border-b border-gray4 ">
                <div className="flex mb-6 lg:mb-0">
                  <div className="w-16 h-16 rounded-full bg-blue1 flex justify-center items-center mr-5">
                    {familyMember.image_url ? (
                      <div className="w-16 h-16 rounded-full overflow-hidden">
                        <img
                          src={familyMember.image_url}
                          alt={`profile ${familyMember.fullname}`}
                        />
                      </div>
                    ) : (
                      <p className="font-inter font-bold text-2xl  text-white w-16 h-16 text-center mt-8 uppercase">
                        {initialName}
                      </p>
                    )}
                  </div>
                  <div className="capitalize">
                    <p className="font-inter font-bold text-2xl">
                      {familyMember.fullname}
                    </p>
                    <p className="font-inter font-medium text-base text-gray5 mb-2">
                      ID Keluarga : {familyMember.family_id}
                    </p>
                    <p className="font-inter font-normal text-base text-gray1">
                      {familyMember.gender} &bull; Usia {familyMember.age.y}{' '}
                      Tahun {familyMember.age.m} Bulan
                    </p>
                  </div>
                </div>

                <div className="block lg:flex lg:w-max lg:flex-col xl:block lg:items-end">
                  <Button
                    type="button-icon"
                    icon={IconEditUnderline}
                    className="border-2 border-blue1 p-1.5 rounded-3 mr-3 lg:mb-2 lg:mr-0 xl:mr-3 w-max"
                    onClick={() => setModalEditProfile(true)}
                  />
                  {/* <Button
                    type="button-icon"
                    icon={IconRemove}
                    className="border-2 border-red1 p-1.5 rounded-3 w-max"
                  /> */}
                  {/* <Button
                    isPrimaryOutline
                    isBtnOutlineSecondary
                    className="whitespace-nowrap w-full lg:w-max"
                    title="Ubah Profil"
                  /> */}
                </div>
              </div>

              <div className="block pb-10 border-b border-gray4 mt-6">
                <div className="w-full flex justify-between items-center mb-5">
                  <p className="text-black font-inter font-semibold text-base">
                    Jadwal Vaksinasi Berikutnya
                  </p>

                  <Button
                    title="+ Jadwal Vaksinasi"
                    className="text-sm font-normal text-blue1 whitespace-nowrap"
                    onClick={async () => {
                      try {
                        await dispatch(
                          checkTicket(
                            `/staff/family-members/ticket-availability/${familyMember.id}`
                          )
                        );
                        props.history.push(
                          `/dashboard/jadwal-vaksinasi/${familyMember.id}`
                        );
                      } catch (error) {
                        setTicketAvailable(true);
                      }
                    }}
                  />
                </div>

                {familyMember.upcoming_queues.length === 0 ? (
                  <p className="text-center font-inter text-gray4 text-sm">
                    Belum ada jadwal selanjutnya
                  </p>
                ) : (
                  familyMember.upcoming_queues?.map((item, i) => (
                    <div className="flex justify-between items-center" key={i}>
                      <p className="font-normal text-black2 text-sm">
                        {/* sebenarnya nama vaksin disini */}
                        Order Number :&nbsp;
                        <span className="font-medium">
                          {item?.order?.order_number || '-'}
                        </span>
                        &nbsp; - {formatDateAsc(item.order.date_plan)}
                      </p>

                      <div className="flex flex-col lg:block">
                        <Button
                          title="Detail"
                          className="py-2 px-4 bg-blue1 rounded-3 text-xs font-medium text-white mr-1.5 mb-2 w-full lg:w-max"
                          onClick={() =>
                            props.history.push(
                              `/dashboard/detail-pasien-vaksin/${item.id}`
                            )
                          }
                        />
                        <Button
                          title="Batal"
                          className="py-2 px-4 bg-red1 rounded-3 text-xs font-medium text-white"
                          onClick={() => {
                            setUpcomingQueueId(item?.id);
                            setIsShowModalCancelOrder(true);
                          }}
                        />
                      </div>
                    </div>
                  ))
                )}

                {/* {} */}
              </div>

              <div className="block pb-10 mt-6">
                <div className="w-full flex justify-between items-center mb-5">
                  <p className="text-black font-inter font-semibold text-base">
                    Riwayat Vaksinasi
                  </p>

                  <Button
                    title="+ Riwayat Vaksinasi"
                    className="text-sm font-normal text-blue1"
                    onClick={() => setShowModalRiwayat(true)}
                  />
                </div>

                {riwayat ? (
                  riwayat.map((item, i) => (
                    <div
                      className="flex justify-between items-center mb-4"
                      key={i}
                    >
                      <p className="font-normal text-black2 text-sm">
                        {item.vaccine_types.map((type, i) => (
                          <span key={i}>{type.name}&nbsp;</span>
                        ))}
                        <span className="">
                          &nbsp;-&nbsp;{item.vaccine?.brand_name}
                        </span>
                      </p>
                      <p className="font-normal text-black2 text-sm">
                        {item.date_taken}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="font-normal text-sm text-gray5 text-center w-full">
                    Belum Ada Riwayat Vaksinasi
                  </p>
                )}
              </div>
            </div>

            <div className="w-full lg:w-2/5">
              <div className="lg:pl-12">
                <p className="font-inter font-bold text-2xl mb-7">
                  Informasi Keluarga
                </p>

                <div className="flex items-center">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p className="text-black font-inter font-semibold text-base mr-7 mb-2">
                            Pemilik Akun
                          </p>
                        </td>
                        <td>
                          <p className="font-normal text-blue1 text-base mb-2">
                            {familyMember?.family?.registrant?.fullname || '-'}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="text-black font-inter font-semibold text-base mr-7 mb-2 ">
                            ID Keluarga
                          </p>
                        </td>
                        <td>
                          <p className="font-normal text-blue1 text-base mb-2 ">
                            {familyMember.family_id}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="text-black font-inter font-semibold text-base mr-7">
                            Nomor Telpon
                          </p>
                        </td>
                        <td>
                          <p className="font-normal text-blue1 text-base">
                            {familyMember?.family?.registrant?.user
                              ?.phone_number || '-'}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="border-b border-gray4 pb-5 mb-8">
                  <p className="font-inter font-bold text-xl mt-14">
                    Anggota Keluarga
                  </p>
                </div>

                {anggotaKeluarga.map((item, i) => (
                  <>
                    {item.id !== parseInt(id) && (
                      <div className="flex justify-between mb-3" key={i}>
                        <p className="text-black font-inter font-semibold text-base mr-7">
                          {item.fullname}
                        </p>

                        <Link
                          to={`/dashboard/profil/${item.id}`}
                          className="font-normal text-blue1 text-base"
                        >
                          Lihat Profile
                        </Link>
                      </div>
                    )}
                  </>
                ))}

                <Button
                  title="+ Anggota Keluarga"
                  className="font-normal text-blue1 text-base mb-2 text-center w-full mt-4"
                  onClick={() => setShowModalTambahAnggota(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-4 px-7">
        <Button
          isPrimaryOutline
          isBtnOutlineSecondary
          title="Kembali"
          className="rounded-3"
          onClick={() => props.history.goBack()}
        />
      </div>

      {isShowModalCancelOrder && (
        <Modal
          idPasien={upcomingQueueId}
          type="batalkan-vaksinasi"
          closeModal={() => setIsShowModalCancelOrder(!isShowModalCancelOrder)}
          tes={() => _getFamilyMember()}
        />
      )}

      {ticketAvailable && (
        <Modal
          type="ready-ticket"
          closeModal={() => {
            return setTicketAvailable(false);
          }}
        />
      )}

      {showModalRiwayat && (
        <Modal
          type="riwayat-vaksinasi"
          idPasien={familyMember.id}
          closeModal={() => setShowModalRiwayat(false)}
        />
      )}

      {modalEditProfile && (
        <Modal
          type="edit-profile"
          idPasien={familyMember.id}
          data={familyMember}
          closeModal={() => setModalEditProfile(false)}
        />
      )}

      {showModalTambahAnggota && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-30">
          <Modal
            type="form-daftar-keluarga"
            data={resultFamily}
            closeModal={() => setShowModalTambahAnggota(false)}
          />
        </div>
      )}
    </div>
  );
}
