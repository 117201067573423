import { IconNoPhoto } from 'assets';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormatPhoneNumber } from 'utils/FormatPhoneNumber/FormatPhoneNumber';

export const CustomerDetailTransaction = ({ data }) => {
  return (
    <>
      <h2 className="font-bold text-lg text-black mb-4">Pemesan</h2>
      <div className="flex border-b border-gray2 pb-7 mb-4">
        <img src={IconNoPhoto} alt="default-avatar" />
        <div className="">
          <p className="font-inter font-semibold text-sm text-black2 ml-5 mr-12 mb-1 capitalize">
            {data?.contact_person_name || '-'}
          </p>
          <p className="font-inter font-normal text-sm text-gray1 ml-5 mr-12">
            {FormatPhoneNumber(data.phone_number)}
          </p>
        </div>
        <Link
          to={`/dashboard/profil/${data.family_member_id}`}
          className="font-inter font-semibold text-sm text-blue1"
        >
          Lihat Profil
        </Link>
      </div>
    </>
  );
};
