import DaftarKeluarga from 'assets/icon/DaftarKeluarga';
import React from 'react';
import { Link } from 'react-router-dom';
import { Antrian, Logout, Scanner } from '../../../assets';
import WithSubMenu from './WithSubMenu';
import { SpeakerWaveIcon } from '@heroicons/react/24/outline';

const typeIcon = (type) => {
  if (type === 'Antrian Klinik') return <Antrian />;
  else if (type === 'Antrian Homecare') return <Antrian />;
  else if (type === 'Antrian Akan Datang') return <Antrian />;
  else if (type === 'Daftar Pasien') return <DaftarKeluarga />;
  else if (type === 'Daftar Pesanan') return <Antrian />;
  else if (type === 'Daftar Produk') return <Antrian />;
  else if (type === 'Scan Barcode') return <Scanner />;
  else if (type === 'Antrian Berjalan') return <Antrian />;
  else if (type === 'Broadcast') return <SpeakerWaveIcon className="h-6 w-6" />;
  return <Logout />;
};

export default function Sidemenu({
  listSubMenu,
  withSubMenu,
  title,
  onClick,
  isActive,
  link,
  showTitle,
  isButton,
  ...props
}) {
  if (withSubMenu) {
    return (
      <WithSubMenu
        isActive={isActive}
        title={title}
        onClick={onClick}
        showTitle={showTitle}
        listSubMenu={listSubMenu}
        href={link}
        {...props}
      />
    );
  }

  if (isButton) {
    return (
      <button
        onClick={onClick}
        className={`side-menu focus:outline-none ${isActive ? 'active' : ''}`}
      >
        <div className="flex md:w-max items-center w-full py-4 focus:outline-none">
          {typeIcon(title)}
          <p
            className={`${
              showTitle ? '' : 'hidden'
            } xl:block ml-3 font-inter font-medium text-sm`}
          >
            {title}
          </p>
        </div>
      </button>
    );
  }

  return (
    <Link
      onClick={onClick}
      className={`side-menu ${isActive ? 'active' : ''}`}
      to={link}
    >
      <div className="flex md:w-max items-center w-full py-4 focus:outline-none">
        {typeIcon(title)}
        <p
          className={`${
            showTitle ? '' : 'hidden'
          } xl:block ml-3 font-inter font-medium text-sm`}
        >
          {title}
        </p>
      </div>
    </Link>
  );
}
