import { IconNoPhoto } from 'assets';
import React from 'react';
import { FormatPhoneNumber } from 'utils/FormatPhoneNumber/FormatPhoneNumber';

export const CustomerQueueDetail = ({ data, onClick }) => {
  return (
    <div className="font-inter pb-3 mb-5">
      <h2 className="font-bold text-lg text-black mb-4">Pemesan</h2>
      <div className="flex">
        <img src={IconNoPhoto} alt="default-avatar" />
        <div className="ml-5">
          <div className="flex justify-between">
            <div className="w-1/2">
              <p className="font-inter font-semibold text-sm text-black2 truncate">
                {data.order?.contact_person_name || '-'}
              </p>
            </div>
            <p
              className="font-inter font-semibold text-sm text-blue1 cursor-pointer"
              onClick={onClick}
            >
              Lihat Detail Pesanan
            </p>
          </div>
          <p className="font-inter font-normal text-sm text-gray1">
            {FormatPhoneNumber(data?.order?.phone_number)}
          </p>
        </div>
      </div>
    </div>
  );
};
