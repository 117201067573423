import cx from 'classnames';
import React from 'react';

export default function Button({
  isDanger,
  isBordered,
  isPrimary,
  isDisabled,
  isSuccess,
  isLoading,
  className = '',
  onClick,
  ...props
}) {
  const baseClassBtn = cx({
    'rounded-3 text-sm font-semibold px-4 py-2 font-inter ease-in-out transition-all duration-300': true,
    'cursor-pointer': !isDisabled,
    'cursor-not-allowed': isDisabled,
  });

  let btnClass = cx({
    'bg-blue1 hover:bg-blue3 text-white': isPrimary && !isBordered,
    'border-blue1 hover:border-blue3 text-blue1 hover:text-blue3':
      isPrimary && isBordered,
    'bg-green1 hover:bg-green-200 text-white': isSuccess && !isBordered,
    'border-green1 text-green1 hover:text-green-200 hover:border-green-200':
      isSuccess && isBordered,
    'bg-red1 hover:bg-red-300 text-white': isDanger && !isBordered,
    'border-red1 hover:border-red-300 text-red1 hover:text-red-300':
      isDanger && isBordered,
    border: isBordered,
  });

  let disableBtnClass = cx({
    'bg-gray-300 border border-gray-300 text-gray-600':
      (isDisabled && !isBordered) || isLoading,
    'border border-gray-300 hover:border-gray-300 text-gray-300':
      (isDisabled && isBordered) || isLoading,
  });

  return (
    <button
      className={`${baseClassBtn} ${
        !isDisabled && !isLoading ? btnClass : disableBtnClass
      } ${className}`}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      {...props}
    >
      {isLoading ? 'Loading...' : props.children}
    </button>
  );
}
