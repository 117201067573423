import { DummyProfile1, IconLoading } from 'assets';
import {
  acceptPayment,
  getDetailPayment,
  rejectPayment,
} from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '..';
import Button from '../Button';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function KonfirmasiPembayaran({ closeModal, idOrder }) {
  const [detailPayment, setDetailPayment] = useState('');
  const [modalTolakPembayaran, setModalTolakPembayaran] = useState(false);
  const [modalKonformasiPembayaran, setModalKonformasiPembayaran] =
    useState(false);
  const [alasantolakPembayaran, setAlasanTolakPembayaran] = useState('');
  const [lightBox, setLightBox] = useState({
    photoIndex: 0,
    isOpen: false,
  });
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();

  const handleClickKonfirmasi = async () => {
    setModalKonformasiPembayaran(true);
    await dispatch(
      acceptPayment(`/staff/payments/accepted/${detailPayment.id}`)
    );
    // window.location.reload();
  };

  const handleTolakPembayaran = async () => {
    await dispatch(
      rejectPayment(
        `/staff/payments/rejected/${detailPayment.id}`,
        alasantolakPembayaran
      )
    );
    setModalTolakPembayaran(false);
    closeModal();
    window.location.reload();
  };

  const _getDetailPayment = async () => {
    const detail = await dispatch(
      getDetailPayment(`/staff/payments/detail/${idOrder}`)
    );
    setDetailPayment(detail);
  };

  useEffect(() => {
    _getDetailPayment();
  }, []);

  if (detailPayment === '') {
    return (
      <div>
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center">
          <div
            className="w-auto max-h-full bg-white px-12 py-12 flex flex-col items-center justify-center  relative"
            style={{ width: 394, height: 621 }}
          >
            <div className="">
              <div className="flex justify-center mb-16 animate-spin">
                <img
                  src={IconLoading}
                  alt="icon loading"
                  className=" justify-center"
                />
              </div>
              <h3 className="font-inter font-bold text-2xl text-center">
                Silahkan Tunggu
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center">
        <div
          className="w-auto max-h-full bg-white px-12 py-12 flex flex-col  relative"
          style={{ width: 394, height: 621 }}
        >
          <div className="absolute top-5 right-7">
            <svg
              className="cursor-pointer"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={closeModal}
            >
              <path
                d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
                fill="#C8C8C8"
              />
            </svg>
          </div>
          <h2 className="font-inter font-bold text-black2 text-2xl mb-6 w-full ">
            Detail Pembayaran
          </h2>

          <div className="font-inter w-full mb-6">
            <p className="font-normal text-sm text-gray1 mb-1">
              Metode Pembayaran
            </p>
            <p className="font-medium text-sm text-black1">
              {detailPayment.payment_method.name}
            </p>
          </div>

          <div className="font-inter w-full mb-6">
            <p className="font-normal text-sm text-gray1 mb-1">
              Rekening Pengirim
            </p>
            <p className="font-medium text-sm text-black1 capitalize">
              {detailPayment.mtf_sender_account_name}
            </p>
          </div>

          <div className="font-inter w-full mb-6">
            <p className="font-normal text-sm text-gray1 mb-1">Bank Pengirim</p>
            <p className="font-medium text-sm text-black1">
              {detailPayment?.sender_bank?.name}
            </p>
          </div>

          <div className="font-inter w-full mb-6">
            <p className="font-semibold text-base text-black1 mb-4">
              Bukti Pembayaran
            </p>
            <div className="flex items-center">
              <img
                src={detailPayment.image_url}
                alt=""
                className="object-cover object-center w-24 h-24 mr-4 cursor-pointer"
                onClick={() => setLightBox({ isOpen: true })}
              />

              {/* <p className="font-semibold text-sm text-gray1">
                IMG1235323423.JPG
              </p> */}
            </div>

            {lightBox.isOpen && (
              <Lightbox
                mainSrc={detailPayment.image_url}
                onCloseRequest={() => setLightBox({ isOpen: false })}
              />
            )}

            <div className="w-full mt-8 flex flex-col items-center justify-center">
              <Button
                title="Konfirmasi Pembayaran"
                className="bg-green2 py-3.5 px-3 font-bold font-inter text-sm text-center text-white rounded-3 mb-7"
                onClick={() => handleClickKonfirmasi()}
              />

              <Button
                title="Tolak Pembayaran"
                className="text-red1 font-bold font-inter text-sm text-center"
                onClick={() => setModalTolakPembayaran(true)}
              />
            </div>
          </div>
        </div>
      </div>

      {modalTolakPembayaran && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-10">
          <div
            className="w-auto max-h-full bg-white px-8 py-12 flex flex-col  relative"
            style={{ width: 394, height: 363 }}
          >
            <div className="absolute top-5 right-7">
              <svg
                className="cursor-pointer"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setModalTolakPembayaran(false)}
              >
                <path
                  d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
                  fill="#C8C8C8"
                />
              </svg>
            </div>
            <h2 className="font-inter font-bold text-black2 text-2xl mb-6 w-full ">
              Alasan Penolakan
            </h2>

            <textarea
              name=""
              id=""
              cols="30"
              rows="7"
              className="rounded-3 font-inter text-sm"
              placeholder="Tuliskan alasan penolakan"
              value={alasantolakPembayaran}
              onChange={(e) => setAlasanTolakPembayaran(e.target.value)}
            ></textarea>

            <div className="w-full flex justify-center mt-8">
              <Button
                isPrimary
                title="Kirim Penolakan"
                className="rounded-3 w-max"
                onClick={() => handleTolakPembayaran()}
              />
            </div>
          </div>
        </div>
      )}

      {modalKonformasiPembayaran && (
        <Modal
          isLoading={isLoading}
          closeModal={() => {
            setModalKonformasiPembayaran(false);
            closeModal();
            window.location.reload();
          }}
          message="Pembayaran Telah Di Konformasi"
        />
      )}
    </div>
  );
}
