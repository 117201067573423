export const IS_LOADING = "IS_LOADING";
export const SET_TOKEN = "SET_TOKEN";
export const DATA_USER = "DATA_USER";
export const LOKASI_VAKSIN = "LOKASI_VAKSIN";
export const STATUS_ANTRIAN = "STATUS_ANTRIAN";
export const NO_INTERNET = "NO_INTERNET";
export const DETAIL_PASIEN = "DETAIL_PASIEN";
export const BTN_DISABLED = "BTN_DISABLED";
export const BTN_PETA_DISABLE = "BTN_PETA_DISABLE";
export const BTN_PETA_VAKSINASI = "BTN_PETA_VAKSINASI";
export const DAFTAR_TITIK_TUJUAN = "DAFTAR_TITIK_TUJUAN";
