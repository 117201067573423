import React from 'react';
import cx from 'classnames';
import { IconClose, Icon } from 'assets';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

const ModalConfirm = ({
  isOpen,
  onClose,
  isLoading = false,
  description,
  okText,
  onOk,
  cancelText,
}) => {
  return (
    <div
      className={cx(
        'fixed inset-0 w-screen h-screen z-50 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 flex justify-center items-center',
        {
          hidden: !isOpen,
        }
      )}
    >
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            onClick={onClose}
          >
            <img src={IconClose} alt="ic-close.svg" className="h-4 w-4" />
          </button>
          <div className="p-6 text-center">
            <div className="flex items-center justify-center">
              <ExclamationCircleIcon className="h-24 w-24 text-red-500" />
            </div>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {description}
            </h3>

            <div className="flex gap-3 justify-center">
              <button
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                onClick={onClose}
              >
                {cancelText}
              </button>
              <button
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                disabled={isLoading}
                onClick={onOk}
              >
                {okText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
