import { Input } from 'components/atoms';
import { TextContent } from 'components/atoms/Text/TextContent';
import { TitleContent } from 'components/atoms/Text/TitleContent';
import { InputNumber } from 'components/atoms/Input/InputNumber';
import React from 'react';
import { useMemo } from 'react';
import { formatNumber } from 'utils';

export const FormCancelTransaction = ({ data, onChangeRefundAmount }) => {
  const amountPaid = useMemo(
    () => data?.mainPaymentChannel?.amount || 0,
    [data?.mainPaymentChannel]
  );

  const paymentMethod = useMemo(
    () => data?.payment_method?.name || '',
    [data?.mainPaymentChannel]
  );

  return (
    <div className="mb-16 w-full animate-AfterLoading">
      <p className="font-inter font-bold text-2xl text-black mb-11 text-center">
        Refund Dana
      </p>

      <div className="w-full mb-9">
        <TitleContent text="Detail Pembayaran Pesanan" className="mb-3" />
        <div className="flex justify-between mb-3">
          <TextContent text="Pembayaran Dilunasi" />
          <TextContent text={`Rp. ${formatNumber(amountPaid)}`} />
        </div>
        <div className="flex justify-between mb-3">
          <TextContent text="Metode Pembayaran" />
          <TextContent text={paymentMethod} className="capitalize" />
        </div>
      </div>

      <InputNumber
        type="number"
        label="Refund"
        placeholder={`Maksimal Refund Rp. ${amountPaid}`}
        onChange={onChangeRefundAmount}
        max={amountPaid}
      />
    </div>
  );
};
