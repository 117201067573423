import { IconRefresh, IconSearch } from 'assets';
import { Button, Input, Modal } from 'components/atoms';
import { getAllFamilyMember } from 'config/Redux/action';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { formatDate } from 'utils';

export default function DaftarKeluarga(props) {
  const [showTambahPasien, setShowTambahPasien] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState('');
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);
  const history = useHistory();

  const _getAllFamilyMember = async () => {
    const family = await dispatch(getAllFamilyMember(`/staff/family-members`));
    setFamilyMembers(family);
  };

  const _searchFamilyMember = async () => {
    const results = await dispatch(
      getAllFamilyMember(
        `/staff/family-members/search?keyword=${keywordSearch}`
      )
    );
    setFamilyMembers(results);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      _getAllFamilyMember();
    } else {
      !keywordSearch ? _getAllFamilyMember() : _searchFamilyMember();
    }
  }, [keywordSearch]);

  return (
    <div className="flex flex-col h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll">
      {/* START : Header Content */}
      <div className="content flex justify-between border-b-2 pb-10 mb-8 border-gray2 font-inter">
        <h2 className="font-bold text-3xl text-black2">Daftar Pasien</h2>
        <p className="font-medium text-sm">
          Terakhir Diperbaharui : {formatDate(new Date())}
        </p>
      </div>
      {/* END : Header Content */}

      <div className="flex items-center justify-between mb-6">
        <Input
          withIcon
          icon={IconSearch}
          type="text"
          name="search"
          className="px-2 py-1 w-1/3"
          placeholder="Masukkan ID/Nama/Pemilik Akun"
          onChange={(e) => {
            setTimeout(() => {
              setKeywordSearch(e.target.value);
            }, 1500);
          }}
        />

        <div className="flex">
          <Button
            type="button-icon"
            icon={IconRefresh}
            className="px-6 rounded bg-white border-2 py-2 border-gray5 ml-6 font-inter font-bold text-blue1"
            onClick={() => {
              window.location.reload();
            }}
          />

          <Button
            title="Tambah Pasien"
            className="bg-blue1 px-7 py-3 font-inter font-bold text-sm text-white rounded-3 lg:mt-0  w-full lg:w-max ml-7 hover:bg-blue3  lg:mb-0 whitespace-nowrap"
            onClick={() => setShowTambahPasien(true)}
          />
        </div>
      </div>

      <div className="flex-1">
        <div className="h-full min-w-full overflow-x-scroll">
          <table className="w-full">
            <thead>
              <tr className="bg-blue4 text-black2">
                <th className="w-1/12 py-3 px-8 border border-blue4">
                  ID Keluarga
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4">
                  Nama Pasien
                </th>
                <th className="w-1/12 py-3 px-2 border border-blue4">
                  Pemilik Akun
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Nomor Telpon
                </th>
                <th className="w-1/12 px-1 py-3  border border-blue4 whitespace-nowrap">
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody>
              {familyMembers.map((member, i) => (
                <tr className="text-center" key={i}>
                  <td className="border border-blue4 py-8 px-4 whitespace-nowrap">
                    {member.family_id}
                  </td>
                  <td className="border border-blue4 py-8 px-4 whitespace-nowrap">
                    {member.fullname}
                  </td>
                  <td className="border border-blue4 py-8 px-4 whitespace-nowrap">
                    {member?.user?.name || '-'}
                  </td>
                  <td className="border border-blue4 py-8 px-4 whitespace-nowrap">
                    {member?.user?.phone_number || '-'}
                  </td>
                  <td className="border border-blue4 py-8 px-4 whitespace-nowrap">
                    <Link
                      to={`/dashboard/profil/${member.id}`}
                      className="bg-blue1 text-white rounded-3 hover:bg-blue3 font-inter font-medium text-xs focus:bg-blue5 focus:outline-none px-4 py-2 mb-1 mr-2"
                    >
                      Profil
                    </Link>
                    <button
                      onClick={() => {
                        localStorage.setItem('family_id', member.family_id);
                        history.push(
                          `/dashboard/profil-keluarga/${member.family_id}`
                        );
                      }}
                      className="bg-blue1 text-white rounded-3 hover:bg-blue3 font-inter font-medium text-xs focus:bg-blue5 focus:outline-none px-4 py-2 mb-1 mr-2"
                    >
                      Detail Keluarga
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-white flex pt-4 font-inter justify-between items-center mt-3.5">
        <p className="text-sm text-black2">{`Data 1 hingga 25 dari 1000`}</p>
        <div className="flex flex-col whitespace-nowrap justify-end md:block">
          <Button
            isGreenOutline
            isBtnOutlineSecondary
            title="Prev Page"
            className="mr-2 w-full md:w-max mb-2"
          />
          <Button isGreenOutline isBtnOutlineSecondary title="Next Page" />
        </div>
      </div>

      {showTambahPasien && (
        <Modal
          type="tambah-pasien"
          closeModal={() => setShowTambahPasien(false)}
        />
      )}
    </div>
  );
}
