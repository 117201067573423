import { Modal } from 'components/atoms';
import React from 'react';

export const ModalAddVaccineType = ({
  onShowModal,
  listVaksin,
  onAddVaccine,
}) => {
  return (
    <Modal
      type="detail-tambah-jenis-vaksin"
      closeModal={onShowModal}
      options={listVaksin}
      fixData={async (value, nomorBatch) => {
        const newVaksin = {
          id: `${new Date().getTime()}`,
          batch: nomorBatch,
          price: value.price,
          vaccine: {
            brand_name: value.brand_name,
            id: value.id,
            price: value.price,
          },
          vaccine_id: value.id,
          vaccine_types: value.vaccine_types,
        };
        onAddVaccine(newVaksin);
      }}
    />
  );
};
