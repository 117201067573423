import React from 'react';

export default function LabelStatus({ type, title, className, withOutline }) {
  if (type === 'Registrasi' || type === 'Pembayaran Dikembalikan') {
    return (
      <span
        className={`  rounded-3 bg-white  text-orange1 font-inter font-medium text-xs ${
          withOutline ? 'border border-orange1 px-4 py-2' : ''
        } ${className || ' '}`}
      >
        {title}
      </span>
    );
  }

  if (type === 'Terkonfirmasi' || type === 'Menunggu Konfirmasi') {
    return (
      <span
        className={`  rounded-3 bg-white text-green1 font-inter font-medium text-xs ${
          withOutline ? ' border border-green1 px-4 py-2' : ''
        } ${className || ' '}`}
      >
        {title}
      </span>
    );
  }

  if (
    type === 'Sudah Checkin' ||
    type === 'Pembayaran Ditolak' ||
    type === 'Dalam Perjalanan' ||
    type === 'Tiba di Lokasi'
  ) {
    return (
      <span
        className={`  rounded-3 bg-white  text-purple1 font-inter font-medium text-xs ${
          withOutline ? 'border border-purple1 px-4 py-2' : ''
        } ${className || ' '}`}
      >
        {title}
      </span>
    );
  }

  if (type === 'Sedang Diukur') {
    return (
      <span
        className={` rounded-3 bg-white  text-purple1 font-inter font-medium text-xs ${
          withOutline ? 'border border-purple1 px-4 py-2 ' : ''
        } ${className || ' '}`}
      >
        {title}
      </span>
    );
  }

  if (type === 'Siap Vaksin') {
    return (
      <span
        className={`  rounded-3 bg-white  text-blue1 font-inter font-medium text-xs ${
          withOutline ? 'border border-blue1 px-4 py-2' : ''
        } ${className || ' '}`}
      >
        {title}
      </span>
    );
  }

  if (type === 'Dalam Proses Vaksinasi') {
    return (
      <span
        className={`  rounded-3 bg-white  text-blue1 font-inter font-medium text-xs ${
          withOutline ? 'border border-blue1 px-4 py-2' : ''
        } ${className || ' '}`}
      >
        {title}
      </span>
    );
  }

  if (type === 'Selesai' || type === 'Sudah Bayar') {
    return (
      <span
        className={` rounded-3 bg-white  text-green1 font-inter font-medium text-xs ${
          withOutline ? 'border border-green1 px-4 py-2 ' : ''
        } ${className || ' '}`}
      >
        {title}
      </span>
    );
  }

  if (type === 'Batal' || type === 'Belum Bayar') {
    return (
      <span
        className={` rounded-3 bg-white  text-red1 font-inter font-medium text-xs ${
          withOutline ? 'border border-red1 px-4 py-2 ' : ''
        } ${className || ' '}`}
      >
        {title}
      </span>
    );
  }

  return (
    <span
      className={`  rounded-3 bg-white  text-gray-400 font-inter font-medium text-xs ${
        withOutline ? 'border border-graytext-gray-400 px-4 py-2' : ''
      } ${className || ' '}`}
    >
      {title}
    </span>
  );
}
