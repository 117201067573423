import React from 'react';
import { getPaymentMethod } from 'utils';

export const GeneratedPayloadConfirmPayment = ({ data }) => {
  const addtionalPayment = data?.additionalPaymentChannel;

  const paymentMethod = getPaymentMethod();
  const refundID = paymentMethod?.filter((item) => item.name === 'Refund')?.[0]
    ?.id;

  if (!addtionalPayment) {
    return {
      order_id: data.id,
    };
  } else {
    return {
      order_id: data.id,
      additionalPaymentChannel: {
        amount: addtionalPayment.amount,
        payment_channel_id: parseInt(addtionalPayment.payment_channel_id),
        payment_method_id:
          addtionalPayment.amount < 0
            ? refundID
            : parseInt(addtionalPayment.payment_method_id),
        note: addtionalPayment.note,
      },
    };
  }
};
