import { IconLoading, IconRightBlue } from 'assets';
import { postRuangBertugas } from 'config/Redux/action';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '..';

export default function RuangBertugas({ closeModal, value, options }) {
  const [selected, setSelected] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [showList, setShowList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClickList = async () => {
    setIsLoading(true);
    await dispatch(postRuangBertugas('staff/rooms/assign/', selectedId));
    setIsLoading(false);
    value(selected);
    closeModal();
    return value(selected);
  };

  if (options.length === 0) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-20">
        <div
          className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative"
          style={{ width: 512, height: 450 }}
        >
          <div className="">
            <div className="flex justify-center mb-16 animate-spin">
              <img
                src={IconLoading}
                alt="icon loading"
                className=" justify-center"
              />
            </div>
            <h3 className="font-inter font-bold text-2xl text-center">
              Silahkan Tunggu
            </h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center">
      <div
        className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative"
        style={{ width: 512, height: 450 }}
      >
        {!options && (
          <div className="">
            <div className="flex justify-center mb-16 animate-spin">
              <img
                src={IconLoading}
                alt="icon loading"
                className=" justify-center"
              />
            </div>
            <h3 className="font-inter font-bold text-2xl text-center">
              Silahkan Tunggu
            </h3>
          </div>
        )}
        {options && (
          <>
            <h2 className="font-inter font-bold text-black2 text-center text-2xl mb-12">
              Ruang Bertugas
            </h2>

            <div className="border py-2 border-gray4 rounded-10 font-inter font-normal mb-16 w-full relative">
              <div className="flex justify-between pl-1 pr-4">
                <input
                  type="text"
                  name="status-antrian"
                  className="focus:ring-0 outline-none border-0 text-sm text-black2 w-full rounded-10"
                  value={selected}
                  placeholder="Ruang Bertugas"
                  autoComplete="off"
                  readOnly
                  onClick={() => setShowList(true)}
                />
                <img
                  src={IconRightBlue}
                  alt="right-arrow"
                  className={`transform ${showList ? 'rotate-90' : ''}`}
                />
              </div>

              <div className="absolute inset-x-0 top-16">
                {showList && (
                  <ul className="font-inter cursor-pointer bg-white border border-gray4 rounded-10 overflow-hidden">
                    {options.map((option) => (
                      <li
                        key={option.id}
                        className="flex items-center justify-between border-b border-gray2 px-4 py-3 hover:bg-blue2 focus-within:bg-blue1 text-sm text-black2"
                        value={option.id}
                        onClick={(e) => {
                          setSelectedId(e.target.value);
                          setSelected(e.target.innerText);
                          setShowList(false);
                        }}
                      >
                        {option.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <Button
              isDisabled={
                selected && selectedId === '' ? true : false || isLoading
              }
              title="Simpan"
              className={
                selected === ''
                  ? 'rounded-10 bg-gray1 px-3 w-full py-4 font-inter text-sm text-white font-medium'
                  : 'bg-blue1 py-4 w-full rounded-10 font-inter font-bold text-white focus:outline-none'
              }
              onClick={handleClickList}
            />
          </>
        )}
      </div>
    </div>
  );
}
