export const formatDate = (date) => {
  const d = new Date(date);
  const dtf = new Intl.DateTimeFormat("id", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    weekday: "long",
  });

  const [
    { value: weekday },
    ,
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dtf.formatToParts(d);

  return `${weekday}, ${month} ${day} ${year}`;
};

export const formatDateAsc = (date) => {
  const d = new Date(date);
  const dtf = new Intl.DateTimeFormat("id", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });

  const [
    { value: day },
    ,
    { value: month },
    ,
    { value: year },
  ] = dtf.formatToParts(d);

  return `${day} ${month} ${year}`;
};

export const formatJam = (jam) => {
  const d = new Date(jam);
  const dtf = new Intl.DateTimeFormat("id", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  const [
    { value: hour },
    ,
    { value: minute },
    ,
    { value: second },
  ] = dtf.formatToParts(d);

  return `${hour} : ${minute} : ${second}`;
};

export const formatDateShort = (date) => {
  const d = new Date(date);
  const dtf = new Intl.DateTimeFormat("id", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dtf.formatToParts(d);

  return `${year}-${day}-${month}`;
};
